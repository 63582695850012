import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  CACHED_DATA,
  GotoById,
  PagesAimation,
  useCacehdImagesList,
  useQuery,
} from "../../component/helper";
import NewsComponent from "../../component/News";
import { URLS } from "../../urls";
import { PreLoader } from "./Layout1";
import Helmate from "react-helmet";
import xHistory from "../../history";

const CahedImages = [{ url: "images/bussiness/news-header.png" }];

const Jobs = (props) => {
  const [state, _setState] = useState({
    news: [],
    isLoading: true,
  });
  const fetchNewsPageData = () => {
    axios
      .get(URLS.GET_NEWS, { params: { per_page: 1000, page_size: 1000 } })
      .then((res) => {
        const _res = {
          ...state,
          isLoading: false,
          news: res.data?.news?.data,
        };

        _setState(_res);

        CACHED_DATA[URLS.GET_NEWS] = _res;
      })
      .catch((errors) => {
        xHistory.push("/not-found");
      });
  };

  useEffect(() => {
    GotoById("news-page-target");
    const cashedRes = CACHED_DATA[URLS.GET_NEWS];
    if (cashedRes) {
      _setState(cashedRes);
    } else fetchNewsPageData();
  }, []);

  const [isImagesLoaded] = useCacehdImagesList(CahedImages);

  return (
    <div
      id='news-page-target'
      style={{ minHeight: "100vh" }}
      className={"page "}>
      <Helmate>
        <title>Quazar News</title>

        <link rel='canonical' href={window.location.origin} />
        <meta name='description' content='Get all Quazar news'></meta>
        <meta name='og:description' content={"Get all Quazar news"}></meta>
        <meta name='og:title' content={"Quazar News"}></meta>
      </Helmate>

      {!state.isLoading && isImagesLoaded ? (
        <PagesAimation>
          <div className='page'>
            <NewsComponent news={state?.news} />
          </div>
        </PagesAimation>
      ) : (
        <PreLoader />
      )}
    </div>
  );
};

export default Jobs;
