import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { PageVariants } from "./animationHelper";
import { RightIcon } from "./Icons";
import $ from "jquery";

export const MainImageHeader = ({ bgUrl, title, style = {}, className }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgUrl})`,
      }}
      className={"img-fluid img-main-section  " + className}>
      <div className='overlay z-1'></div>
      <div className='image-title z-2'>
        <img
          src='images/Component 7 – 1.svg'
          className='overlay-text z-1'
          alt=''
          style={{ ...style }}
        />
        <span className='position-relative z-2  fwb'>{title}</span>
      </div>
    </div>
  );
};

export const PagesAimation = ({ children, className, ...res }) => {
  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={PageVariants}
      className={className}>
      {children}
    </motion.div>
  );
};

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    if (0 < windowSize.width && windowSize.width < 600) {
      setBreakPoint(breakpoints[0]);
    }
    if (600 < windowSize.width && windowSize.width < 960) {
      setBreakPoint(breakpoints[600]);
    }
    if (960 < windowSize.width && windowSize.width < 1280) {
      setBreakPoint(breakpoints[960]);
    }
    if (1280 < windowSize.width && windowSize.width < 1920) {
      setBreakPoint(breakpoints[1280]);
    }
    if (windowSize.width >= 1920) {
      setBreakPoint(breakpoints[1920]);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);

  let result = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    isMobile: false,
    isSmall: false,
  };
  if (breakpoint === "xs" || breakpoint === "sm") result.isMobile = true;
  if (breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md")
    result.isSmall = true;
  result[breakpoint] = true;

  return result;
};

export default useBreakpoint;
const breakpoints = {
  0: "xs",
  600: "sm",
  960: "md",
  1280: "lg",
  1920: "xl",
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function _ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen((xxx, ss) => {
      if (xxx?.search?.includes("id") || xxx?.pathname?.includes("news")) {
        return;
      }
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        100,
        function () {},
      );
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}
export const ScrollToTop = withRouter(_ScrollToTop);

export const ShowAllItem = ({ onClick = () => {} }) => {
  return (
    <div className='cont' style={{ minHeight: "310px" }}>
      <div className='slider-show-all-item ' onClick={onClick}>
        <div
          className='flexCenter icon-link'
          style={{
            width: "75px",
            height: "75px",
            backgroundColor: "black",
            borderRadius: "200px",
          }}>
          <RightIcon width='30px' height='30px' />
        </div>
        <div className='text-center  fz-m mt-2'>Show all</div>
      </div>
    </div>
  );
};

export const useApi = (initialUrl, initialData) => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await axios(url);
        setData(result.data);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [url]);
  return [{ data, isLoading, isError }, setUrl];
};
export const CACHED_DATA = {};
export const useAxios = (configParams) => {
  const cachedResponse = CACHED_DATA[configParams.url];

  const [res, setRes] = useState(null);
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(Boolean(!cachedResponse));

  useEffect(() => {
    if (cachedResponse) {
      setRes(cachedResponse);
      setLoading(false);
    } else fetchDataUsingAxios(configParams);
  }, []);

  const fetchDataUsingAxios = async () => {
    await axios
      .request(configParams)
      .then((res) => {
        setRes(res.data);
        CACHED_DATA[configParams.url] = res.data;
      })
      .catch((err) => setErr(err))
      .finally(() => setLoading(false));
  };
  return [res, loading, err];
};
export const useCacehdImagesList = (imageList) => {
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;

        loadImg.onload = (x) => {
          resolve(image.url);
        };
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(imageList.map((image) => loadImage(image)))
      .then(() => setIsImagesLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  return [isImagesLoaded];
};

export function GotoById(el, margin = 100) {
  var ele = document.getElementById(el);
  const rect = ele?.getBoundingClientRect();

  let top = rect.top + window.scrollY;

  window.scrollTo(0, top - margin);
}
export function GotoByIdInElelem(target, parent) {
  var ele = document.getElementById(target);
  const rect = ele?.getBoundingClientRect();

  const top = rect.top + window.scrollY;
  parent?.scrollTo(0, top - 100, { behavior: "smooth" });
}

export const CheckImage = (path) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(path);
    img.onerror = () => reject();

    img.src = path;
  });
export let CachedImages = [{ imageId: "", blobUrl: "" }]; // this to store list of loaded images I fetched them from the dumb api
export const IsImageCached = (imageUrl) => {
  return CachedImages.find((i) => i.imageId === imageUrl);
};

export const FetchImage = (filePath) => {
  return axios
    .get(filePath, { responseType: "blob" })
    .then(async (res) => {
      const objectUrl = URL.createObjectURL(res.response.data);
      CachedImages.push({ imageId: filePath, blobUrl: objectUrl });
      return objectUrl;
    })
    .catch((error) => {
      return "";
    });
};

export const setActiveNavItem = (activeNav) => {
  $(".nav-item").removeClass("active");
  $(`.nav-item:contains(${activeNav})`).addClass("active");
};
