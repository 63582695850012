import { motion } from "framer-motion";
import { setActiveNavItem } from "./helper";

export const OnViewAimation = ({
  children,
  initial = {},
  animation = {},
  delay = 0,
  duration = 0.8,
  stiffness,
  type = "spring",
  ...res
}) => {
  const defualtAnimation = {
    opacity: 1,
    transition: { duration, type: type, delay, stiffness },
  };
  return (
    <motion.div
      // onViewportEnter={() => {
      //   alert("enter ");
      //   setActiveNavItem("About");
      // }}
      // onViewportLeave={() => {
      //   alert("leave ");

      //   setActiveNavItem("Home");
      // }}
      initial={{
        opacity: 0,
        ...initial,
      }}
      {...res}
      viewport={{ once: true, amount: "some", margin: "-100px" }}
      // viewport={{ once: true }}
      whileInView={{ ...defualtAnimation, ...animation }}>
      {children}
    </motion.div>
  );
};

export const PageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
