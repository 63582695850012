import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";

import useBreakpoint, { MainImageHeader } from "./helper";
import axios from "axios";
import { URLS } from "../urls";
import { LeftIcon, RightIcon } from "./Icons";
import Slider from "react-slick";
import { motion } from "framer-motion";
// import { useLinkedIn } from "react-linkedin-login-oauth2";
import { LinkedIn } from "react-linkedin-login-oauth2";
import $ from "jquery";
import { toast } from "react-toastify";

const CareerComponent = ({ positions = [], vacancies = [] }) => {
  // const { linkedInLogin } = useLinkedIn({
  //   clientId: "86vhj2q7ukf83q",

  //   redirectUri: `${window.location.origin}/linkedin`,
  //   onSuccess: (code) => {
  //     console.log(code);
  //   },
  //   scope: "r_emailaddress r_liteprofile",
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  const [state, _setState] = useState({
    // ----------****-----------
    selectedVacancyId: vacancies[0]?.vacancy_id,
    selectedPosistion: { id: "all" },
    currentIndex: 0,
    canNext: true,
  });

  const setState = (newData) => {
    _setState({ ...state, ...newData });
  };
  const getvacanciesByPosition = (pid) =>
    vacancies.filter((v) => {
      if (pid == "all") return true;
      return v.position_id == pid;
    });
  const updateCurrentIndex = (newIndex) => {
    setState({ ...state, currentIndex: newIndex });
  };
  const renderPositions = positions.map((item, index) => {
    return (
      <PositionsItem
        key={item.id + String(item.id == state.selectedPosistion.id)}
        item={item}
        active={item.id == state.selectedPosistion.id}
        onClick={() => {
          const tt = getvacanciesByPosition(item.id);
          setState({
            selectedPosistion: item,
            selectedVacancyId: tt[0]?.vacancy_id,
          });
        }}
      />
    );
  });

  const renderVacancy = getvacanciesByPosition(state.selectedPosistion.id).map(
    (item, index) => {
      return (
        <div key={item.vacancy_id}>
          <VacancyItem
            key={item.vacancy_id}
            title={item.title}
            location={item.location.name}
            active={item.vacancy_id == state.selectedVacancyId}
            onClick={() => {
              setState({ selectedVacancyId: item.vacancy_id });
            }}
          />
        </div>
      );
    },
  );
  // const canNext = getvacanciesByPosition(state.selectedPosistion.id).length > 3; //state.currentIndex + 1 !== state?.items?.length;

  const canBack = true; //getvacanciesByPosition(state.selectedPosistion.id).length > 3; //state.currentIndex > 0;
  const refSlider = useRef(null);
  const { isMobile, isSmall } = useBreakpoint();

  var settings = {
    dots: false,
    speed: 600,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: "20px",
    // focusOnSelect: isSmall ? true : false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 900,
        settings: {
          adaptiveHeight: false,
          variableWidth: false,
          slidesToShow: renderPositions.length > 2 ? 2.1 : 2,
          centerPadding: "20px",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <section className='section' id='career-page'>
        <Container>
          <Row className='justify-content-center'>
            <MainImageHeader
              bgUrl='images/bussiness/career.png'
              title='Careers'
            />
          </Row>
          {/* positions */}
          <Row className={"my-4 justify-content-center"}>
            <Row
              className={"flexStart mb-2  px-lg-5 " + (isMobile && " pe-0 ")}>
              {isMobile ? (
                <Col
                  className={" my-2" + (isMobile && " pe-0 ")}
                  md={12}
                  lg={12}>
                  <div
                    id='business-slider'
                    className='owl-carousel owl-theme testi-content overflow-hidden'>
                    <Slider
                      afterChange={(currentIndex) => {
                        // updateCurrentIndex(currentIndex);
                      }}
                      ref={(slider) => (refSlider.current = slider)}
                      {...settings}>
                      {renderPositions}
                    </Slider>
                  </div>
                </Col>
              ) : (
                renderPositions
              )}
            </Row>
            <Row>
              <Col lg={12} className='flexBetween  my-2  '>
                <div className='text-white fw-bolder  '>
                  {renderVacancy.length > 0
                    ? "Selected a job vacancy"
                    : "No job vacancy"}{" "}
                </div>
                {!isMobile && renderVacancy.length > 4 && (
                  <div className='flexCenter'>
                    <div
                      data-disabled={!canBack}
                      className='icon-link mx-2'
                      onClick={() => {
                        if (canBack) refSlider.current.slickPrev();
                      }}>
                      <LeftIcon />
                    </div>
                    <div
                      className='icon-link mx-2'
                      data-disabled={!state.canNext}
                      onClick={() => {
                        if (state.canNext) refSlider.current.slickNext();
                      }}>
                      <RightIcon width='30' />
                    </div>{" "}
                  </div>
                )}
              </Col>
              {renderVacancy.length > 0 && !isMobile && (
                <Col className=' my-2' md={12} lg={12}>
                  <div
                    id='business-slider'
                    className='owl-carousel owl-theme testi-content overflow-hidden'>
                    <Slider
                      key={JSON.stringify(state.selectedPosistion)}
                      beforeChange={(old, next) => {}}
                      onInit={(a, b, c) => {}}
                      afterChange={(currentIndex) => {
                        updateCurrentIndex(currentIndex);
                        // setState({ ...state, canNext: true });
                      }}
                      ref={(slider) => (refSlider.current = slider)}
                      {...settings}>
                      {renderVacancy}
                    </Slider>
                  </div>
                </Col>
              )}
              {renderVacancy.length > 0 && isMobile && (
                <div className='vacancy-item-container'>{renderVacancy}</div>
              )}
            </Row>
          </Row>
          <Row className='justify-content-center'>
            <Col className='flexCenter my-2 mt-lg-2 ' md={12} lg={7}>
              {renderVacancy.length > 0 ? (
                <CvForm vacancy_id={state.selectedVacancyId} />
              ) : (
                <div style={{ width: "100px", height: "8rem" }}></div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

function PositionsItem({ item, active, ...res }) {
  return (
    <Col
      sx={12}
      sm={12}
      md={12}
      lg='3'
      className='my-2 posistion-item-container '>
      <div className='posistion-item  ' {...res}>
        <div
          className={`btn btn-primary btn-lg fz-xl fw-bolder btn-outline-dark  w-100  text-capitalize ${
            active ? "bg-primary" : ""
          }`}
          style={{ borderRadius: "50px" }}>
          {item.name}
        </div>
      </div>
    </Col>
  );
}
function VacancyItem({ title, location, id, active, ...res }) {
  return (
    <div
      {...res}
      className={"vacancy-item  flexBetween  " + (active ? " active" : "")}>
      <span className='title text-white text-capitalize fz-lg'>{title}</span>
      <span className='text-white-50 fz-lg'>{location}</span>
    </div>
  );
}
export default CareerComponent;

const CvForm = ({ vacancy_id }) => {
  const { isMobile } = useBreakpoint();
  const [sending, setSending] = useState(false);
  const coverRef = useRef();
  const cvRef = useRef();
  const [files, setFiles] = useState({ cover: "", cv: "" });
  const handleChangeCover = (event) => {
    setFiles({ ...files, cover: event.target.files[0]?.name });
  };
  const handleChangeCV = (event) => {
    setFiles({ ...files, cv: event.target.files[0]?.name });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.getElementById("cv-form");

    if (sending) {
      return;
    }
    setSending(true);
    const data = new FormData(event.target);
    data.append("vacancy_id", vacancy_id);
    data.append("linkedin", "linkedin");
    data.append("location", "location");
    let url = URLS.POST_CARRER;
    axios
      .post(url, data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        setSending(false);

        setShowSentMsg(true);
        toast.success("Thanks for Applying!");
        form.reset();
        setFiles({ cover: "", cv: "" });
        setTimeout(() => {
          setShowSentMsg(false);
        }, 1000);
      })
      .catch(() => {
        setSending(false);
      });
  };
  const [showSentMsg, setShowSentMsg] = React.useState(false);
  const handleLinkedinSuccess = (data) => {
    debounce(() => {
      if (data) {
        return axios
          .get(URLS.LINKEDIN + data)
          .then((res) => {
            $("#first_name").val(res.data?.data?.first_name);
            $("#last_name").val(res?.data?.data?.last_name);
            $("#email").val(res.data?.data?.email);
            if (res?.data?.data?.mobile_number) {
              $("#mobile_number").val(res?.data?.data?.mobile_number);
            }
          })
          .catch((error) => {
            toast.error(error?.message || "Unable to obtain your profile.");
          });
      }
    }, 1000)();
  };
  const fNameRef = useRef();
  const lNameRef = useRef();
  const emailRef = useRef();
  return (
    <Form
      onSubmit={handleSubmit}
      id='cv-form'
      className='w-100 h-100 ff-reservation cv-form-font'
      autoComplete='off'>
      <Container>
        <Row>
          <Col lg={12} className=' my-2 mt-0'>
            <h6 className='text-primary mb-0 fw-bold text-center'>
              Apply for the selected job
            </h6>
          </Col>
        </Row>

        <Row>
          {isMobile && (
            <Col className='my-1 px-1 mb-3' lg={12}>
              <LinkedIn
                clientId='77bmu4mqrd3k8y'
                clientSecret='31n947Z0yjhufs19'
                scope='r_emailaddress,r_liteprofile,w_member_social'
                redirectUri={`${window.location.origin}/linkedin`}
                onSuccess={handleLinkedinSuccess}
                onError={(error) => {
                  console.log(error);
                }}>
                {({ linkedInLogin }) => (
                  <div
                    onClick={linkedInLogin}
                    className={`flexCenter btn btn-primary  btn-lg fz-xl fw-bolder  btn-outline-blue  w-100  text-capitalize `}
                    style={{ borderRadius: "10px" }}>
                    <img
                      height={"25px"}
                      className='me-2'
                      alt=''
                      src='images/icons/linkedin.svg'
                    />{" "}
                    <span>Import from LinkedIn</span>
                  </div>
                )}
              </LinkedIn>
            </Col>
          )}
          <Col className='my-1  px-1' xs={6} lg={6}>
            <Input
              ref={(r) => (r = fNameRef)}
              type='text'
              required
              name='first_name'
              bsSize='sm'
              id='first_name'
              placeholder='First name'
            />
          </Col>
          <Col className='my-1  px-1' xs={6} lg={6}>
            <Input
              ref={lNameRef}
              type='text'
              name='last_name'
              required
              bsSize='sm'
              id='last_name'
              placeholder='Last name'
            />
          </Col>
          <Col className='my-1 px-1' xs={6} md={6} lg={6}>
            <Input
              ref={emailRef}
              type='email'
              name='email'
              bsSize='sm'
              id='email'
              placeholder='Email'
              required
            />
          </Col>
          <Col className='my-1 px-1' xs={6} md={6} lg={6}>
            <Input
              type='text'
              name='mobile_number'
              bsSize='sm'
              required
              id='mobile_number'
              placeholder='Mobile number'
            />
          </Col>
          {!isMobile && (
            <Col className='my-1 px-1 mb-3' lg={12}>
              <LinkedIn
                redirectUri={`${window.location.origin}/linkedin`}
                redirect_uri={`${window.location.origin}/linkedin`}
                clientId='77bmu4mqrd3k8y'
                clientSecret='31n947Z0yjhufs19'
                scope='r_emailaddress,r_liteprofile,w_member_social'
                onSuccess={handleLinkedinSuccess}
                onError={(error) => {}}>
                {({ linkedInLogin, disabled }) => (
                  <div
                    disabled={disabled}
                    onClick={linkedInLogin}
                    className={`flexCenter btn btn-primary  btn-lg fz-xl fw-bolder  btn-outline-blue  w-100  text-capitalize `}
                    style={{ borderRadius: "10px" }}>
                    <img
                      height={"25px"}
                      className='me-2'
                      alt=''
                      src='images/icons/linkedin.svg'
                    />
                    <span>Import from LinkedIn</span>
                  </div>
                )}
              </LinkedIn>
            </Col>
          )}
          <Col className='my-1 px-1' xs={6} md={6} lg={6}>
            <Input
              type='text'
              name='position'
              required
              bsSize='sm'
              id='position'
              placeholder='Position'
            />
          </Col>
          <Col className='my-1 px-1' xs={6} md={6} lg={6}>
            <Input
              type='number'
              name='years'
              required
              bsSize='sm'
              id='years'
              placeholder='Years of Experience'
            />
          </Col>
          <Col className='my-1 px-1' xs={6} md={6} lg={6}>
            <InputGroup
              className='cutom-upload'
              onClick={() => cvRef.current.click()}>
              <input
                type='file'
                accept='application/pdf , image/jpeg'
                className='d-none'
                name='resume'
                id='resume'
                onChange={handleChangeCV}
                ref={cvRef}
              />
              <Input
                className='show-input'
                type='text'
                // readOnly
                required
                bsSize='sm'
                value={files.cv}
                placeholder='Resume'></Input>
              <InputGroupText>
                <img src='images/icons/upload.svg'></img>
              </InputGroupText>
            </InputGroup>
          </Col>
          <Col className='my-1 px-1' xs={6} md={6} lg={6}>
            <InputGroup
              className='cutom-upload'
              onClick={() => coverRef.current.click()}>
              <input
                accept='application/pdf , image/jpeg'
                type='file'
                name='portfolio'
                id='portfolio'
                onChange={handleChangeCover}
                className='d-none'
                ref={coverRef}
              />
              <Input
                className='show-input'
                type='text'
                // readOnly
                bsSize='sm'
                value={files.cover}
                placeholder='Cover letter, Portfolio'></Input>
              <InputGroupText>
                <img src='images/icons/upload.svg'></img>
              </InputGroupText>
            </InputGroup>
          </Col>

          <Col className='my-1 px-1' md={12}>
            <Input
              type='textarea'
              name='messege'
              bsSize='sm'
              id='messege'
              rows='4'
              className='form-control-sm form-control'
              style={{ height: "auto" }}
              placeholder='Your messege'
            />
          </Col>
          <Col className='my-1 px-1 mt-3 mx-auto ' xs={6} lg={12}>
            <Button
              readOnly={sending}
              disabled={sending}
              className={`flexCenter btn btn-primary   btn-lg   btn-outline-light  w-100  text-capitalize  active `}
              style={{ borderRadius: "10px" }}>
              <motion.span
                key={JSON.stringify(showSentMsg)}
                initial={{ opacity: 0, y: -100 }}
                animate={{ y: 0, opacity: 1 }}
                className='fw-bolder'>
                {sending
                  ? "Applying..."
                  : showSentMsg
                  ? "Thanks for Applying!"
                  : "Apply"}
              </motion.span>
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

let timer;
function debounce(func, timeout = 500) {
  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(args);
    }, timeout);
  };
}
