import { motion } from "framer-motion";
import React, { useEffect } from "react";
import useBreakpoint from "../component/helper";
import parse from "html-react-parser";
import { PRIVACY_DATA } from "../component/Footer/Footer";
import { Container } from "reactstrap";
import { PreLoader } from "./Layout1/Layout1";

const PrivacyPage = (props) => {
  useEffect(() => {}, []);
  const { isMobile } = useBreakpoint();

  return PRIVACY_DATA ? (
    <div
      id=''
      style={{ minHeight: "50vh", paddingTop: "5rem" }}
      className={" page w-100 "}>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ y: 0, opacity: 1 }}
        style={{ color: "white" }}
        className='text-left'>
        <Container className='mb-4'>
          <h4 className='text-primary ff-raleway mb-3'>Privacy and policy</h4>
          {PRIVACY_DATA && parse(PRIVACY_DATA)}
        </Container>
      </motion.div>
    </div>
  ) : (
    <div style={{}}>
      <PreLoader />
    </div>
  );
};

export default PrivacyPage;
