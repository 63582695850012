import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Feature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "v-pills-work",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className='section' id='features'>
          <Container>
            <Row className='justify-content-center'>
              <Col lg={12}>
                <div className='text-center text-white   my-md-2 py-md-1'>
                  <h4 className='text-bold '>Additional Support</h4>
                </div>
              </Col>
            </Row>
            <div className='features-content'>
              <Row className='align-items-center support-container pb-0'>
                {this.props.list.map((l, index) => (
                  <SupportItem
                    key={l.name}
                    title={l.name}
                    image_url={l.image_url}>
                    {l.content}
                  </SupportItem>
                ))}
              </Row>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;

const SupportItem = ({ image_url, title, children }) => {
  return (
    <Col md={6} lg={3} className='my-2'>
      <div className='core-value-item text-center z-2  '>
        <img src={image_url} alt={title} width='60' />
        <h6 className='text-primary my-2'>{title}</h6>
        <p
          className='text-white fz-sm ff-raleway textJustify'
          style={{ minHeight: "115px", fontFamily: "raleway" }}>
          {children}
        </p>
      </div>
    </Col>
  );
};
