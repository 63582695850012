import { motion } from "framer-motion";
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { OnViewAimation } from "./animationHelper";
import useBreakpoint from "./helper";

const OurLocations = ({ list, what_we_do_in_hub_paragraph }) => {
  const { isMobile } = useBreakpoint();

  const OurLocationList = (
    <Row className=''>
      <Col lg={4}>
        <h3
          style={{ wordSpacing: "10px", letterSpacing: "6px" }}
          className='box-container side-title  w-100 text-center flexCenter text-primary m-0 left-side-height ff-reservation'>
          OUR LOCATIONS
        </h3>
      </Col>

      <Col md={12} lg={8} className='position-relative '>
        <div
          id='cities-list'
          className='box-container  d-flex flex-column justify-content-between text-center  left-side-height '>
          {list.map((c) => {
            return (
              <h6 className='text-white fw-bold  ' key={c.name}>
                {c.name}
              </h6>
            );
          })}
        </div>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      <section className='section  ff-raleway' id='our-location'>
        <div className='overlay1'> </div>
        <Container>
          <Row>
            <Col sm={12} md={4}>
              <motion.div
                key='dwa'
                initial={{
                  x: "-100",
                  opacity: 0,
                }}
                viewport={{ once: true, amount: "some", margin: "-100px" }}
                // viewport={{ once: true }}
                whileInView={{
                  transition: {
                    duration: 0.2,
                    delay: 0.3,
                    type: "spring",
                    stiffness: 80,
                  },
                  opacity: 1,
                  x: 0,
                }}>
                <div>
                  <Row className='mb-0  mb-md-3 '>
                    <Col xs='auto' className='top-left-side'>
                      {/* <div className='overlay box-container zIndex-1 top-side-height  '></div> */}
                      <div className=' box-container top-side-height zIndex-2 flexCenter flex-column  '>
                        <h1 className='mb-0  text-white fw-bolder position-relative  ff-reservation'>
                          Quazar HUB
                        </h1>
                        <span
                          className='position-relative text-white fw-bolder fz-m p-0 m-0 '
                          style={{}}>
                          Coming soon
                        </span>
                      </div>
                    </Col>
                  </Row>
                  {!isMobile && OurLocationList}
                </div>
              </motion.div>
            </Col>
            <Col sm={12} md={8}>
              <OnViewAimation
                key='ss'
                delay={0.3}
                className='box-container h-100 d-flex flex-column   position-relative '
                animation={{ x: 0, opacity: 1 }}
                // type='just'
                duration={0.2}
                stiffness={80}
                initial={{ x: "100", opacity: 0 }}>
                <div>
                  <h5
                    style={{ fontFamily: "reservation" }}
                    className='text-primary mt-2 text-align-mobile  ff-reservation'>
                    What We Do in the HUB?
                  </h5>
                  <div className='text-white textJustify fz-m'>
                    {what_we_do_in_hub_paragraph}
                  </div>
                  <div className='flexCenter flex-1 p-sm-0 p-md-5 p-3'>
                    <img
                      className='location-img text-center'
                      src='/images/locations.png'
                      alt=''
                    />
                  </div>
                </div>
              </OnViewAimation>
            </Col>
            {isMobile && (
              <Col sm={12} className='mt-3  position-relative '>
                {OurLocationList}
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default OurLocations;
