export const ServerURL = "https://api.quazarinvestment.com/api/";


export const URLS = {
  HOME_PAGE_DATA: ServerURL + "home",
  VACABCIES_PAGE_DATA: ServerURL + "vacancies",
  GET_POSITIONS: ServerURL + "positions",
  POST_CARRER: ServerURL + "carrer",
  GET_NEWS: ServerURL + "news",
  GET_WHAT_WE_DO: ServerURL + "whatwedo",
  SEND_MSG: ServerURL + "contact",
  GET_CONTACT_INFO: ServerURL + "info",
  LINKEDIN: "https://admin.quazarinvestment.com/auth/linkedin/callback?code=",
};
