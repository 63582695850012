import React, { useEffect } from "react";
import useBreakpoint, {
  GotoById,
  PagesAimation,
  useAxios,
} from "../../component/helper";
import WhatWeDo from "./Jobs/WhatWeDo";
import Feature from "../../component/Feature";
import { URLS } from "../../urls";
import { OnViewAimation } from "../../component/animationHelper";
import { PreLoader } from "./Layout1";

const Jobs = (props) => {
  const [data, isLoading] = useAxios({
    url: URLS.GET_WHAT_WE_DO,
    method: "get",
  });
  useEffect(() => {
    GotoById("root");
  }, []);
  const { isMobile } = useBreakpoint();
  return (
    <div style={{ minHeight: "100vh" }} className={"page "}>
      {!isLoading && data ? (
        <PagesAimation>
          <div className='page what-we-do-page-section '>
            <WhatWeDo data={data} />
            <OnViewAimation
              delay='0.3'
              duration={2}
              animation={{ opacity: 1, x: 0, y: isMobile ? "-160px" : "0" }}
              initial={{ opacity: 0, x: 0, y: isMobile ? "-160px" : "0" }}>
              <Feature list={data?.addtional_support || []} />
            </OnViewAimation>
          </div>
        </PagesAimation>
      ) : (
        <PreLoader />
      )}
    </div>
  );
};

export default Jobs;
