import React from "react";
//import style
import "./assets/css/pe-icon-7.css";

import "./assets/scss/themes.scss";
import "./App.css";
import Layout1 from "./pages/Layout1/Layout1";

function App() {
  return <Layout1></Layout1>;
}

export default App;
