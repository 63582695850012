import NewsComponent from "./News";

export const LeftIcon = ({ disabled, ...res }) => (
  <svg
    id='arrow-left-circle'
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='48'
    {...res}
    viewBox='0 0 48 48'>
    <path
      id='Path_5754'
      data-name='Path 5754'
      d='M24,45A21,21,0,1,0,3,24,21,21,0,0,0,24,45Zm0,3A24,24,0,1,0,0,24,24,24,0,0,0,24,48Z'
      // fill='#8c8b8b'
      fillRule='evenodd'
    />
    <path
      id='Path_5755'
      data-name='Path 5755'
      d='M21.687,30.687a1.5,1.5,0,0,0,0-2.124l-7.94-7.937,7.941-7.939a1.5,1.5,0,0,0-2.124-2.124l-9,9a1.5,1.5,0,0,0,0,2.124l9,9a1.5,1.5,0,0,0,2.124,0Z'
      transform='translate(3.374 3.374)'
      // fill='#8c8b8b'
      fillRule='evenodd'
    />
    <path
      id='Path_5756'
      data-name='Path 5756'
      d='M30.375,18.375a1.5,1.5,0,0,0-1.5-1.5h-15a1.5,1.5,0,0,0,0,3h15A1.5,1.5,0,0,0,30.375,18.375Z'
      transform='translate(4.125 5.625)'
      // fill='#8c8b8b'
      fillRule='evenodd'
    />
  </svg>
);
export const MouseScrollIcon = ({ disabled, ...res }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='48'
    viewBox='0 0 30 48'>
    <g id='mouse-line' transform='translate(-8 -2)'>
      <path
        id='Path_17'
        data-name='Path 17'
        d='M23,50A15,15,0,0,1,8,35V17a15,15,0,0,1,30,0V35A15,15,0,0,1,23,50ZM23,5A12,12,0,0,0,11,17V35a12,12,0,0,0,24,0V17A12,12,0,0,0,23,5Z'
        fill='#fff'
      />
      <path
        id='Path_18'
        data-name='Path 18'
        d='M18.5,18A1.5,1.5,0,0,1,17,16.5v-6a1.5,1.5,0,1,1,3,0v6A1.5,1.5,0,0,1,18.5,18Z'
        transform='translate(4.5 3.5)'
        fill='#fed17e'
      />
    </g>
  </svg>
);
export const DownScrollIcon = ({ disabled, style = {}, ...res }) => (
  <svg
    version='1.1'
    id='Layer_1'
    x='0px'
    y='0px'
    viewBox='0 0 330 330'
    style={{
      enableBackground: "new 0 0 330 330",
      fill: "#FED17E",
      stroke: "#FED17E",
      ...style,
    }}>
    <path
      id='XMLID_225_'
      d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const CallIcon = ({ disabled, ...res }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.005'
    height='15.999'
    viewBox='0 0 16.005 15.999'
    {...res}>
    <path
      id='phone'
      d='M17.523,19.374h-.1C5.327,18.679,3.61,8.476,3.37,5.363a1.846,1.846,0,0,1,1.7-1.988H8.459A1.231,1.231,0,0,1,9.6,4.15l.935,2.3a1.231,1.231,0,0,1-.271,1.329L8.957,9.1a5.766,5.766,0,0,0,4.664,4.677l1.335-1.323a1.231,1.231,0,0,1,1.335-.252l2.32.929a1.231,1.231,0,0,1,.758,1.144v3.249A1.846,1.846,0,0,1,17.523,19.374ZM5.216,4.606a.615.615,0,0,0-.615.615V5.27c.283,3.643,2.1,12.257,12.885,12.873a.615.615,0,0,0,.653-.578V14.279l-2.32-.929L14.053,15.1l-.3-.037C8.4,14.4,7.678,9.042,7.678,8.986l-.037-.3L9.388,6.925,8.465,4.606Z'
      transform='translate(-3.364 -3.375)'
    />
  </svg>
);
export const BackToTop = ({ disabled, ...res }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    // fill='currentColor'
    class='bi bi-arrow-up-circle-fill'
    {...res}
    viewBox='0 0 16 16'>
    <path d='M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z' />{" "}
  </svg>
);
export const RightIcon = ({ color, ...res }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='48'
    {...res}
    viewBox='0 0 48 48'>
    <g id='arrow-left-circle' transform='translate(48 48) rotate(180)'>
      <path
        id='Path_5754'
        data-name='Path 5754'
        d='M24,45A21,21,0,1,0,3,24,21,21,0,0,0,24,45Zm0,3A24,24,0,1,0,0,24,24,24,0,0,0,24,48Z'
        fill={color}
        fillRule='evenodd'
      />
      <path
        id='Path_5755'
        data-name='Path 5755'
        d='M21.687,30.687a1.5,1.5,0,0,0,0-2.124l-7.94-7.937,7.941-7.939a1.5,1.5,0,0,0-2.124-2.124l-9,9a1.5,1.5,0,0,0,0,2.124l9,9a1.5,1.5,0,0,0,2.124,0Z'
        transform='translate(3.375 3.374)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        id='Path_5756'
        data-name='Path 5756'
        d='M30.375,18.375a1.5,1.5,0,0,0-1.5-1.5h-15a1.5,1.5,0,0,0,0,3h15A1.5,1.5,0,0,0,30.375,18.375Z'
        transform='translate(4.125 5.625)'
        fill={color}
        fillRule='evenodd'
      />
    </g>
  </svg>
);
export const Twitter = ({ color = "#90999f", ...res }) => (
  <svg
    id='Component_59_1'
    data-name='Component 59 – 1'
    xmlns='http://www.w3.org/2000/svg'
    width='56'
    height='56'
    {...res}
    viewBox='0 0 56 56'>
    <path
      id='Path_411'
      data-name='Path 411'
      d='M395.538,97.416H355.872a8.167,8.167,0,0,0-8.167,8.167v39.667a8.167,8.167,0,0,0,8.167,8.167h39.667a8.167,8.167,0,0,0,8.167-8.167V105.583A8.167,8.167,0,0,0,395.538,97.416Zm-6.01,21.5v.879c0,.11,0,.22,0,.33a18.294,18.294,0,0,1-18.458,18.127,16.6,16.6,0,0,1-10.019-3.164,4.851,4.851,0,0,0,1.581.174,13.27,13.27,0,0,0,8.09-2.816,6.66,6.66,0,0,1-6.153-4.57,2.987,2.987,0,0,0,1.231.176,5.429,5.429,0,0,0,1.755-.176,6.425,6.425,0,0,1-5.273-6.328,5.926,5.926,0,0,0,2.989.877,6.98,6.98,0,0,1-2.813-5.448,6.723,6.723,0,0,1,.878-3.336,18.178,18.178,0,0,0,13.361,6.856,3.425,3.425,0,0,1-.176-1.406,6.605,6.605,0,0,1,6.5-6.5,6.275,6.275,0,0,1,4.747,2.115,16.114,16.114,0,0,0,4.044-1.583A6.842,6.842,0,0,1,389,116.626a14.623,14.623,0,0,0,3.691-1.055A14.251,14.251,0,0,1,389.529,118.912Z'
      transform='translate(-347.705 -97.416)'
    />
  </svg>
);
export const Telegram = ({ color = "#90999f", ...res }) => (
  <svg
    id='Component_84_1'
    data-name='Component 84 – 1'
    xmlns='http://www.w3.org/2000/svg'
    width='56'
    height='56'
    {...res}
    viewBox='0 0 56 56'>
    <path
      id='Path_414'
      data-name='Path 414'
      d='M281.544,97.416H241.878a8.167,8.167,0,0,0-8.167,8.167v39.667a8.167,8.167,0,0,0,8.167,8.167h39.667a8.167,8.167,0,0,0,8.167-8.167V105.583A8.167,8.167,0,0,0,281.544,97.416Zm-6.411,17.952L270.7,136.244c-.311,1.485-1.205,1.838-2.45,1.152l-6.749-4.984-3.243,3.15a1.739,1.739,0,0,1-1.351.675l.461-6.883,12.52-11.3c.551-.486-.119-.726-.849-.281l-15.455,9.752-6.679-2.084c-1.44-.441-1.45-1.433.326-2.145l26.017-10.033C274.432,112.725,275.582,113.548,275.134,115.368Z'
      transform='translate(-233.711 -97.416)'
    />
  </svg>
);
export const LinkedIn = ({ color = "#90999f", ...res }) => (
  <svg
    id='Path_301'
    data-name='Path 301'
    xmlns='http://www.w3.org/2000/svg'
    width='56'
    height='56'
    {...res}
    viewBox='0 0 56 56'>
    <path
      id='Path_301-2'
      data-name='Path 301'
      d='M170.651,97.416H124.783a5.067,5.067,0,0,0-5.066,5.065v45.869a5.067,5.067,0,0,0,5.066,5.067h45.869a5.066,5.066,0,0,0,5.066-5.067h0V102.481A5.065,5.065,0,0,0,170.651,97.416Zm-33.6,48.354a1.474,1.474,0,0,1-1.475,1.475H129.3a1.474,1.474,0,0,1-1.475-1.475V119.464a1.475,1.475,0,0,1,1.475-1.475h6.276a1.475,1.475,0,0,1,1.475,1.475Zm-4.613-30.26a5.961,5.961,0,1,1,5.963-5.961,5.962,5.962,0,0,1-5.963,5.961Zm37.407,30.379a1.355,1.355,0,0,1-1.354,1.355h-6.734a1.355,1.355,0,0,1-1.356-1.355h0V133.55c0-1.841.54-8.066-4.809-8.066-4.151,0-4.992,4.261-5.161,6.173v14.232a1.355,1.355,0,0,1-1.356,1.355h-6.515a1.355,1.355,0,0,1-1.354-1.355h0V119.349a1.355,1.355,0,0,1,1.354-1.356h6.513a1.355,1.355,0,0,1,1.355,1.356h0v2.294c1.539-2.31,3.826-4.09,8.7-4.09,10.782,0,10.72,10.073,10.72,15.608v12.728Z'
      transform='translate(-119.717 -97.416)'
    />
  </svg>
);
export const Facebook = ({ color = "#90999f", ...res }) => (
  <svg
    id='Path_302'
    data-name='Path 302'
    xmlns='http://www.w3.org/2000/svg'
    width='56'
    height='56'
    {...res}
    viewBox='0 0 56 56'>
    <path
      id='Path_302-2'
      data-name='Path 302'
      d='M225.171,97.416H184.264a7.546,7.546,0,0,0-7.549,7.542v40.914a7.547,7.547,0,0,0,7.549,7.544h20.175l.035-20.011h-5.2a1.227,1.227,0,0,1-1.227-1.223l-.018-6.456a1.228,1.228,0,0,1,1.223-1.232h5.194V118.26c0-7.233,4.418-11.172,10.87-11.172h5.29a1.227,1.227,0,0,1,1.224,1.231v5.45A1.228,1.228,0,0,1,220.6,115h-3.245c-3.509,0-4.189,1.667-4.189,4.114v5.4h7.712a1.227,1.227,0,0,1,1.226,1.228,1.143,1.143,0,0,1-.008.141l-.764,6.452a1.227,1.227,0,0,1-1.218,1.088H213.2l-.034,20h12a7.546,7.546,0,0,0,7.543-7.546V104.962A7.546,7.546,0,0,0,225.171,97.416Z'
      transform='translate(-176.714 -97.416)'
    />
  </svg>
);
export const MenuSVG = ({ color = "#fed17e", ...res }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='28.8'
    height='24'
    viewBox='0 0 28.8 24'>
    <g id='menu' transform='translate(-4.5 -6.75)'>
      <path
        id='Path_5791'
        data-name='Path 5791'
        d='M4.5,27H33.3v2.25H4.5Z'
        transform='translate(0 1.5)'
        fill={color}
      />
      <path
        id='Path_5792'
        data-name='Path 5792'
        d='M4.5,13.5H33.3v2.25H4.5Z'
        transform='translate(0 0.5)'
        fill={color}
      />
      <path
        id='Path_5793'
        data-name='Path 5793'
        d='M4.5,20.25H33.3V22.5H4.5Z'
        transform='translate(0 1)'
        fill={color}
      />
      <path
        id='Path_5794'
        data-name='Path 5794'
        d='M4.5,6.75H33.3V9H4.5Z'
        fill={color}
      />
    </g>
  </svg>
);
export const ExpendMenuSVG = ({ color = "#fed17e", ...res }) => (
  <svg version='1.1' width='256' height='256' viewBox='0 0 256 256' {...res}>
    <defs></defs>
    <g transform='translate(128 128) scale(1.21 1.21)'>
      <g
        style={{
          stroke: color,
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: color,
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform='translate(-104.85 -104.85000000000001) scale(2.33 2.33)'>
        <rect
          x='-13.59'
          y='39.95'
          rx='0'
          ry='0'
          width='117.17'
          height='10.1'
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: color,
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=' matrix(0.7071 -0.7072 0.7072 0.7071 -18.6396 45.0044) '
        />
        <rect
          x='39.95'
          y='-13.59'
          rx='0'
          ry='0'
          width='10.1'
          height='117.17'
          style={{
            stroke: "NewsComponent",
            strokeWidth: "1NewsComponent",
            strokeDasharray: "NewsComponent",
            strokeLinecap: "buttNewsComponent",
            strokeLinejoin: "miterNewsComponent",
            strokeMiterlimit: "10NewsComponent",
            fill: color,
            fillRule: "nonzeroNewsComponent",
            opacity: "1NewsComponent",
          }}
          transform=' matrix(0.7071 -0.7071 0.7071 0.7071 -18.6396 45.0004) '
        />
      </g>
    </g>
  </svg>
);
