import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Container,
  NavbarToggler,
  Row,
  Col,
  Fade,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
// Import Logo
import logodark from "../../assets/images/logonotxt.png";
import logolight from "../../assets/images/logo.png";

//import icon

import xHistory from "../../history";
import useBreakpoint, { GotoById, GotoByIdInElelem } from "../helper";
import { BackToTop, CallIcon, ExpendMenuSVG, MenuSVG } from "../Icons";

const NavbarPage = (props) => {
  // render() {
  const [state, setState] = useState({ isOpenMenu: false });

  const location = useLocation();

  const IsNavActive = (urlId) => {
    if (
      location.pathname.length > 2 &&
      urlId !== "/" &&
      location.pathname.includes(urlId)
    ) {
      return " active cursor-pointer";
    }

    if (location.pathname === urlId) {
      return " active cursor-pointer";
    }
    return " cursor-pointer";
  };
  const { isMobile } = useBreakpoint();
  const toggle = () => {
    if (isMobile) setState({ isOpenMenu: !state.isOpenMenu });
  };

  const renderButton = () => {
    return (
      <>
        <div className='d-flex flex-column'>
          <button
            onClick={() => {
              window.location = `tel:${props?.data?.phone}`;
            }}
            className='btn btn-primary br-5   p-1  mb-2 call-us flexCenter '>
            <span className=''>
              <CallIcon style={{ width: 13 }} />
              Call us
            </span>
          </button>
          <button
            onClick={() => {
              xHistory.push("/careers");
              toggle();
            }}
            className='btn btn-secondary white-hover br-5 p-1 '>
            Careers
          </button>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Navbar
        expand='lg'
        fixed={props.top === true ? "top" : ""}
        className={
          props.navClass +
          " fixed-top navbar-custom sticky sticky-dark align-items-center py-0 " +
          (state.isOpenMenu ? " vh-100 align-content-start   " : "")
        }
        id='navbar'>
        <Container className='px-0' style={{ zIndex: 11 }}>
          {/* LOGO */}
          {isMobile && (
            <Row className='menu-logo-mobile-row'>
              <Col className='flexStart'>
                <Fade in={isMobile && !state.isOpenMenu}>
                  {isMobile && !state.isOpenMenu && (
                    <button
                      onClick={() => {
                        window.location = "tel:+97143200233";
                      }}
                      style={{ borderRadius: "5px" }}
                      className='btn btn-primary btn-outline-white btn-sm active  '>
                      Call us
                    </button>
                  )}
                </Fade>
              </Col>
              <Col>
                {isMobile && (
                  <Link className='logo  ' to='/'>
                    <div className='flexCenter'>
                      {state.isOpenMenu ? (
                        <Fade in={state.isOpenMen}>
                          <img
                            onClick={() => {
                              toggle();
                            }}
                            className=' mt-3 mt-md-0'
                            src={logolight}
                            alt=''
                            height={70}
                          />
                        </Fade>
                      ) : (
                        <>
                          {" "}
                          <img
                            className='first mt-3 mt-md-0'
                            src={logolight}
                            alt=''
                            height={isMobile ? 70 : 83}
                          />
                          <img
                            className='second'
                            src={logodark}
                            alt=''
                            height={50}
                          />
                        </>
                      )}
                    </div>
                  </Link>
                )}
              </Col>
              <Col className='flexEnd'>
                <NavbarToggler
                  onClick={toggle}
                  className='position-relative'
                  style={{ height: "40px", width: "40px" }}>
                  <Fade
                    unmountOnExit
                    in={state.isOpenMenu}
                    className='position-absolute nav-toggle-icon'>
                    <ExpendMenuSVG
                      style={{ fill: "white", height: "18", width: "18" }}
                    />
                  </Fade>
                  <Fade
                    className='position-absolute nav-toggle-icon'
                    unmountOnExit
                    in={!state.isOpenMenu}>
                    <MenuSVG
                      style={{ fill: "white", height: "18", width: "18" }}
                    />
                  </Fade>
                </NavbarToggler>
              </Col>
            </Row>
          )}
          <Fade
            tag={Row}
            in={!isMobile || state.isOpenMenu}
            id='navbarCollapse'
            unmountOnExit
            className=' navbar-collapse justify-content-center justify-content-md-end'>
            <Col xs={8} sm={8} md={5} id='navbar-navlist'>
              <Row className='navbar-nav align-items-center   '>
                <Col></Col>
                <Col>
                  <NavItem
                    data-nav='home'
                    onClick={() => {
                      xHistory.push("/");
                      toggle();
                    }}
                    className={IsNavActive("/")}>
                    <NavLink className={"active "}>Home</NavLink>
                  </NavItem>
                </Col>
                <Col>
                  <NavItem
                    data-nav='about'
                    className={IsNavActive("about")}
                    onClick={() => {
                      if (document.location?.pathname === "/")
                        GotoById("_about");
                      else {
                        xHistory.push("/?s=_about");
                      }
                      toggle();
                    }}>
                    <NavLink
                      className={" "}
                      onClick={() => {
                        if (document.location?.pathname === "/")
                          GotoById("_about");
                        else {
                          xHistory.push("/?s=_about");
                        }

                        toggle();
                      }}>
                      About us
                    </NavLink>
                  </NavItem>
                </Col>
                <Col className=''>
                  <NavItem
                    className={IsNavActive("what-we-do")}
                    onClick={() => {
                      xHistory.push("/what-we-do");
                      toggle();
                    }}>
                    <NavLink className=' active'>What we do</NavLink>
                  </NavItem>
                </Col>
              </Row>
            </Col>
            {!isMobile && (
              <Col lg={2}>
                <Link className='logo ' to='/'>
                  <div className='flexCenter'>
                    <img className='first' src={logolight} alt='' height={83} />
                    <img className='second' src={logodark} alt='' height={50} />
                  </div>
                </Link>
              </Col>
            )}
            <Col md={5} xs={8} sm={8} className=''>
              <Row className='navbar-nav align-items-center  mt-0 '>
                <Col className='left-item-nav  '>
                  <NavItem data-nav='our-location' className='text-right '>
                    <NavLink
                      className={"cursor-pointer"}
                      onClick={() => {
                        if (location.pathname === "/")
                          GotoById("our-location", 50);
                        else xHistory.push("/?s=our-location");
                        toggle();
                      }}>
                      Quazar HUB
                    </NavLink>
                  </NavItem>
                </Col>
                <Col className='left-item-nav '>
                  <NavItem
                    className={IsNavActive("news") + " text-center "}
                    onClick={() => {
                      xHistory.push("/news");
                      toggle();
                    }}>
                    <NavLink className=''>News</NavLink>
                  </NavItem>
                </Col>
                <Col className=' left-item-nav '>
                  <NavItem
                    className={IsNavActive("footer") + " text-left "}
                    onClick={() => {
                      const ell = document.getElementsByClassName(
                        "card-content-container",
                      );
                      if (ell?.length > 0) {
                        GotoByIdInElelem("news-footer", ell[0]);
                      } else GotoById("footer");
                      toggle();
                    }}>
                    <NavLink className=''>Contact Us</NavLink>
                  </NavItem>
                </Col>
                <Col className=' left-item-nav '>
                  {!isMobile ? (
                    <NavItem>{renderButton()}</NavItem>
                  ) : (
                    <NavItem>
                      <div>
                        <button
                          onClick={() => {
                            xHistory.push("/careers");
                            toggle();
                          }}
                          style={{ borderRadius: "5px" }}
                          className='btn btn-primary    py-2 '>
                          <b>Careers</b>
                        </button>
                      </div>
                    </NavItem>
                  )}
                </Col>
              </Row>{" "}
            </Col>
          </Fade>
        </Container>

        <Fade
          // key={String(state.isOpenMenu)}
          // in={state.isOpenMenu }
          unmountOnExit
          id='nav-overly'
          className={`${isMobile ? " active  " : " "} ${
            state.isOpenMenu ? " extend " : ""
          }`}></Fade>
        <div
          id='backToTop'
          onClick={() => {
            GotoById("root");
          }}>
          <BackToTop style={{ width: "45px", height: "auto" }}></BackToTop>
        </div>
      </Navbar>
    </React.Fragment>
  );
};
// }
export default NavbarPage;
