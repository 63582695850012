import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { MainImageHeader, useQuery } from "./helper";
import xHistory from "../history";
import { LinkedIn, Telegram, Twitter } from "./Icons";
import Helmate from "react-helmet";
import $ from "jquery";
// Import client   Image
import parse from "html-react-parser";
import Footer from "./Footer/Footer";
import { Link } from "react-router-dom";

const NewsComponent = ({ news = [] }) => {
  return (
    <React.Fragment>
      <section className='section' id='news-page'>
        <Container>
          <Row className='justify-content-center'>
            <MainImageHeader
              bgUrl='images/bussiness/news-header.png'
              title='News'
            />
          </Row>
          <Row className='justify-content-center my-3 mt-4'></Row>
          <AnimateSharedLayout type='crossfade'>
            <Store news={news} />
          </AnimateSharedLayout>
        </Container>
      </section>
    </React.Fragment>
  );
};

function Store({ news }) {
  let query = useQuery();
  const id = query.get("id");
  const blogVariants = {
    enter: { transition: { staggerChildren: 0.1 } },
    exit: { transition: { staggerChildren: 0.1 } },
  };
  const slides = news.map((item, index) => {
    return (
      <Col className='mb-4' key={index} md={12} lg={index === 0 ? 12 : 6}>
        <motion.div
          initial='initial'
          animate='enter'
          exit='exit'
          variants={blogVariants}>
          <WhatWeDoCard
            isSelected={item.id == id}
            key={item?.id + "_" + index}
            item={item}
          />
        </motion.div>
      </Col>
    );
  });

  return (
    <>
      <Row>
        <AnimatePresence exitBeforeEnter initial={false}>
          {slides}
        </AnimatePresence>
      </Row>
      <AnimatePresence>
        {id && <Item item={news.find((x) => x.id == id)} key='item' />}
      </AnimatePresence>
    </>
  );
}

function WhatWeDoCard({ item }) {
  return (
    <Link to={"/news?id=" + item.id}>
      <motion.div
        onClick={(e) => {
          e.preventDefault();
          xHistory.push("/news?id=" + item.id);
        }}
        className='news-card cursor-pointer ff-raleway'
        layoutId={`card-container-${item.id}`}>
        <div className='img overflow-hidden '>
          <div
            style={
              {
                // backgroundImage: `url(${item.image_url})`,
              }
            }
            className='img-fluid img position-relative how  '>
            <motion.div
              className='text-white fz-m my-3 text-description z-2 position-relative'
              // className="title-container"
              layoutId={`description-container-${item.id}`}>
              <h6 className='text-primary fw-800'>{item.title}</h6>
              <span className='text-white-70'>
                {new Date(item.updated_at).toLocaleDateString()}
              </span>

              <div className='ff-raleway text-white textJustify'>
                {parse(item.content || "<></>")}
              </div>
            </motion.div>

            <div
              style={{
                backgroundImage: `url(${item.image_url})`,
              }}
              id='overlay-background-news'></div>
          </div>
        </div>

        <div></div>
      </motion.div>
    </Link>
  );
}
function WhatWeDoCardFull({ item }) {
  var currentLocation = encodeURIComponent(
    `https://admin.quazarinvestment.com/api/news/seo/${item.id}`,
  );

  var element = document.getElementById("navbar");
  useEffect(() => {
    let navOldSatuts = element.classList.contains("nav-sticky");
    var backToTop = document.getElementById("backToTop");

    backToTop.classList.add("d-none");
    document.body.classList.add("noscroll");
    if (!navOldSatuts) {
      element.classList.add("nav-sticky");
    }

    return () => {
      document.body.classList.remove("noscroll");
      backToTop.classList.remove("d-none");

      if (!navOldSatuts) {
        element.classList.remove("nav-sticky");
      }
    };
  }, []);
  const telegram = () => {
    const share_url = "https://t.me/share/url?url=" + currentLocation;

    return share_url;
  };
  return (
    <div className='news-card-full ff-raleway'>
      <Helmate>
        <title>{item.title}</title>
        <link rel='canonical' href={window.location.origin} />
        <meta
          name='description'
          content={$(item.content).text().substr(0, 200) + "..."}></meta>
        <meta name='og:title' content={item.title}></meta>
        <meta
          name='og:description'
          content={$(item.content).text().substr(0, 200) + "..."}></meta>
        <meta property='og:url' content={window.location.origin} />
        <meta property='og:image' content={item.image_url} />
      </Helmate>
      <motion.div
        style={{
          backgroundImage: `url(${item.image_url})`,
        }}
        className='news-image z-2'
        layoutId={`card-container-${item.id}`}></motion.div>
      <Container className='position-relative z-3 container-description'>
        <Row>
          <Col xs={10} sm={9} lg={9}>
            <motion.div className='text-white fz-m my-3 text-description'>
              <div className='flexBetween mb-4'>
                <h5 className='text-white mb-0 ff-raleway'>{item.title}</h5>
                <span className='text-white-50 fz-m ff-raleway '>
                  {new Date(item.created_at).toLocaleDateString()}
                </span>
              </div>
              <div className='textJustify'>
                {parse(item.content || "<></>")}
              </div>
            </motion.div>
          </Col>
          <Col
            xs={2}
            sm={2}
            lg={2}
            className='pt-lg-5 pt-2 mt-lg-5 mt-1 text-center'>
            <div className='links-container'>
              <div
                className='icon-link  gray my-3'
                onClick={() => {
                  // $(".social-share.linkedin").on("click", function() {
                  //     url = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl;
                  // socialWindow(url);
                  // })

                  window.open(
                    "https://twitter.com/intent/tweet?url=" +
                      currentLocation +
                      "&text=Quazar",
                  );
                }}>
                <Twitter width='40' />
              </div>

              <div
                className='icon-link gray my-3'
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/shareArticle?url=${currentLocation}`,
                  );
                }}>
                <LinkedIn width='40' />
              </div>
              <div
                className='icon-link gray my-3'
                onClick={() => {
                  window.open(telegram());
                }}>
                <Telegram width='40' />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div id='news-footer'></div>
      <Footer />
    </div>
  );
}
export default NewsComponent;

function Item({ item }) {
  if (!item) {
    xHistory.push("/not-found");
  }
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className='card-content-container open'>
        <WhatWeDoCardFull key={"full" + item.id} item={item} />
      </motion.div>
    </>
  );
}
