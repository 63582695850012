import React, { useEffect, useState } from "react";
import { PagesAimation, useCacehdImagesList } from "../../component/helper";
import CareerComponent from "../../component/Career";
import axios from "axios";
import { URLS } from "../../urls";
import { PreLoader } from "./Layout1";

// const CareerComponent = React.lazy(() => import("../../component/Career"));

const Jobs = (props) => {
  const [state, _setState] = useState({
    positions: [],
    vacancies: [],
    isLoading: true,
  });
  const fetchVacanciesPageData = () => {
    axios
      .all([axios.get(URLS.VACABCIES_PAGE_DATA), axios.get(URLS.GET_POSITIONS)])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          _setState({
            ...state,
            isLoading: false,
            vacancies: responseOne.data,
            positions: [
              {
                id: "all",
                name: "All Positions",
              },
              ...responseTwo.data,
            ],
          });
        }),
      )
      .catch((errors) => {
        // react on errors.
      });
  };
  const CahedImages = [{ url: "images/bussiness/career.png" }];

  useEffect(() => {
    fetchVacanciesPageData();
  }, []);
  const [isImagesLoaded] = useCacehdImagesList(CahedImages);

  return (
    <div
      style={{ minHeight: "100vh" }}
      className={"page " + (state.isLoading ? " invisible " : "")}>
      {!state.isLoading && isImagesLoaded ? (
        <PagesAimation>
          <CareerComponent
            positions={state.positions}
            vacancies={state.vacancies}
          />
        </PagesAimation>
      ) : (
        <PreLoader />
      )}
    </div>
  );
};

export default Jobs;
