import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { OnViewAimation } from "../../../component/animationHelper";
import useBreakpoint, {
  GotoById,
  MainImageHeader,
  useQuery,
} from "../../../component/helper";

// Import client   Image

const WhatWeDo = ({ data }) => {
  const { what_we_do_paragraph, all_service_names, what_we_do_services } = data;

  const slides = what_we_do_services.map((item, key) => {
    return <WhatWeDoCard key={item.id} item={item} index={key} />;
  });
  const params = useQuery();
  useEffect(() => {
    let target = params.get("id");

    if (target) {
      setTimeout(() => {
        GotoById(target);
      }, 200);
    }
  }, [params]);
  const { isMobile } = useBreakpoint();
  const columnCount = isMobile ? all_service_names.length / 2 + 1 : 4;
  return (
    <React.Fragment>
      <section className='section  overflow-hidden' id='what-we-do-section'>
        <Container>
          <Row className='justify-content-center'>
            <MainImageHeader
              style={{
                left: "-31%",
                top: "-14px",
                width: "146%",
                height: "118%",
              }}
              className=' we-do '
              bgUrl='images/bussiness/main.png'
              title={
                <>
                  What {!isMobile && <br />}
                  We Do
                </>
              }
            />
          </Row>
          <Row className='justify-content-center my-3 mt-4'>
            <Col lg={12}>
              <p className='text-white fz-m textJustify'>
                {what_we_do_paragraph}
              </p>
            </Col>
            <Row>
              {chunk(all_service_names, columnCount).map((d, index) => {
                return (
                  <Col key={index} xs={6} sm={6} md={4} lg={3}>
                    <ul
                      key={index}
                      style={{ listStyleType: "none" }}
                      className='text-white  item-list-ul fz-m'>
                      {d.map((_d) => {
                        return (
                          <li
                            className='read-more text-nowrap'
                            onClick={() => GotoById(_d.id)}
                            key={_d.name}>
                            {_d.name}{" "}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              fill='currentColor'
                              className='bi bi-chevron-down'
                              viewBox='0 0 16 16'>
                              <path
                                fillRule='evenodd'
                                d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                              />
                            </svg>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                );
              })}
            </Row>
          </Row>
          <Row>
            <Col
              lg={12}
              className={
                isMobile
                  ? "  flexCenter text-gray-1 mt-3 "
                  : " flexBetween mb-2 text-white  "
              }>
              <h5 className=' fw-bold mb-0 '>Our Business lines</h5>{" "}
            </Col>
            <Col lg={12}>{slides}</Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

const chunk = (array, chunkSize) => {
  let result = [];
  let data = array.slice(0);
  while (data[0]) {
    result.push(data.splice(0, chunkSize));
  }
  return result;
};

function WhatWeDoCard({ item, index }) {
  const { isMobile } = useBreakpoint();
  return (
    <div className='what-we-do-card' id={item.id}>
      <OnViewAimation
        className=' img  w-50'
        animation={{ x: 0 }}
        initial={{ x: index % 2 === 0 ? "-30" : "30" }}>
        <div
          style={{
            backgroundImage: `url(${item.image_url})`,
          }}
          className='img-fluid img'>
          <div className=' image-title  '>
            {item.image_title || item.name?.split(" ")[0]}
          </div>
        </div>
      </OnViewAimation>

      <OnViewAimation
        className='text-white fz-m my-3 text-description'
        animation={{ x: 0 }}
        initial={{ x: index % 2 === 0 ? "30" : "-30" }}>
        <h6 className='text-primary'>{item.title}</h6>
        <div className='textJustify'>{item.content}</div>
      </OnViewAimation>

      <div></div>
    </div>
  );
}
export default WhatWeDo;
