import React, { useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Slider from "react-slick";
import { LeftIcon, RightIcon } from "./Icons";
import useBreakpoint, { ShowAllItem } from "./helper";
import parse from "html-react-parser";
import xHistory from "../history";

// Import client   Image

const NewsBar = ({ list }) => {
  const [state, setState] = useState({ currentIndex: 0 });

  var settings = {
    // vertical: true,
    dots: false,
    speed: 300,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    centerPadding: "20px",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };
  const updateCurrentIndex = (newIndex) => {
    setState({ ...state, currentIndex: newIndex });
  };
  const slides = list.map((item, key) => {
    return <NewsCard key={item.id} item={item} />;
  });
  const { isMobile } = useBreakpoint();

  let canNext = !(
    state.currentIndex + settings.slidesToShow >=
    list?.length + 0.5
  );

  if (isMobile) {
    canNext = !(state.currentIndex >= list?.length);
  }

  const canBack = state.currentIndex > 0;

  const refSlider = useRef(null);
  return (
    <React.Fragment>
      <section className='section' id='clients'>
        <Container>
          <Row>
            <Col lg={12} className='flexBetween mb-3 '>
              <h3 className='text-primary fw-bolder'>News </h3>
              {!isMobile && (
                <div className='flexCenter'>
                  <div
                    data-disabled={!canBack}
                    className='icon-link mx-2'
                    onClick={() => {
                      if (canBack) refSlider.current.slickPrev();
                    }}>
                    <LeftIcon />
                  </div>
                  <div
                    className='icon-link mx-2'
                    data-disabled={!canNext}
                    onClick={() => {
                      if (canNext) refSlider.current.slickNext();
                    }}>
                    <RightIcon width='30' />
                  </div>{" "}
                </div>
              )}
            </Col>
            <Col lg={12} style={{ minHeight: "19rem" }}>
              <div
                id='business-slider'
                className='owl-carousel owl-theme testi-content'>
                {isMobile ? (
                  [
                    ...slides.slice(0, 2),
                    <div className='show-all-news-bar'>
                      <ShowAllItem
                        onClick={() => {
                          xHistory.push("/news");
                        }}
                      />
                    </div>,
                  ]
                ) : (
                  <Slider
                    afterChange={(currentIndex) => {
                      updateCurrentIndex(currentIndex);
                    }}
                    ref={(slider) => (refSlider.current = slider)}
                    {...settings}>
                    {slides}
                    <ShowAllItem
                      onClick={() => {
                        xHistory.push("/news");
                      }}
                    />
                  </Slider>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

function NewsCard({ item }) {
  return (
    <div className='news-bar-card'>
      <div className='img  w-100'>
        <div
          style={{
            backgroundImage: `url(${item.image_url})`,
          }}
          className='img-fluid img'>
          <div
            className='image-title  '
            onClick={() => xHistory.replace("/news?id=" + item.id)}>
            <p className='fz-m fw-bolder mb-0 xtitle '>{item.title}</p>

            <p className='text-white fz-m my-1 text-description textJustify'>
              {parse(item.content)}
            </p>
            <p className='text-primary fw-bold mb-0 fz-m read-more '>
              Read more...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsBar;
