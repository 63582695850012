import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import xHistory from "../../history";
import { AnimatePresence, motion, useAnimation } from "framer-motion";

import useBreakpoint, { GotoById, useQuery } from "../../component/helper";
import { OnViewAimation } from "../../component/animationHelper";
import { DownScrollIcon, MouseScrollIcon } from "../../component/Icons";
// Import Background Image
import $ from "jquery";

const Section = ({ data }) => {
  const { header } = data;
  const params = useQuery();
  useEffect(() => {
    let target = params.get("s");

    if (target) {
      setTimeout(() => {
        GotoById(target);
      }, 200);
    }
  }, [params]);
  useEffect(() => {
    let firstMount = true;
    const sections = document.querySelectorAll("section");
    window.onscroll = () => {
      var current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 100) {
          current = section.getAttribute("id");
        }
      });

      if ($(`.nav-item[data-nav=${current}]`)?.length > 0) {
        $(".nav-item").removeClass("active");
        $(`.nav-item[data-nav=${current}]`).addClass("active");
      }
    };

    if (firstMount) {
      $(`.nav-item[data-nav=home]`).addClass("active");
      firstMount = false;
    }
    return () => {
      console.log("remimvoe ");
      ["home", "about", "our-location"].forEach((section) => {
        $(`.nav-item[data-nav=${section}]`).removeClass("active");
      });
      // $(".nav-item.active").removeClass("active");
    };
  }, []);
  const { isSmall } = useBreakpoint();
  return (
    <React.Fragment>
      <section className='hero-1-bg ' id='home'>
        {!isSmall && (
          <div
            className='mouse-scroll-icon'
            onClick={() => {
              GotoById("_about");
            }}>
            <Container className='flexCenter'>
              <MouseScrollIcon />
            </Container>
          </div>
        )}
        <Container className='main-images-contianer'>
          <Row className='align-items-center justify-content-between h-100'>
            <Col
              className='order-2 order-lg-1 '
              md={{ order: 2, size: 12 }}
              lg={{ order: 2, size: 5 }}
              xl={{ order: 2, size: 5 }}>
              <OnViewAimation
                delay='0.3'
                animation={{ x: 0 }}
                initial={{ x: "-200" }}>
                <div
                  id='image1-container'
                  className='  flexCenter position-relative  '>
                  <div id='bg-first-section' className='z-1'>
                    <img src='/images/Component 7 – 1.svg' />
                  </div>
                  <h1 className=' position-relative hero-1-title bio text-light py-4 z-2 mb-0    '>
                    {header.title}
                    <br />
                    <span className=' '>{header.paragraph}</span>
                  </h1>
                </div>

                <OnViewAimation
                  id='main-button'
                  animation={{ x: 0 }}
                  className='d-block mt-3'
                  initial={{ x: "-10" }}>
                  <div
                    style={{
                      letterSpacing: "1px",
                    }}
                    onClick={() => {
                      GotoById("clients");
                    }}
                    className=' button-with-icon btn btn-primary btn-lg btn-outline-white ff-raleway read-more  '>
                    <span className='fz-m'>Explore our services </span>
                    <span className='ml-2 right-icon'>&#8594;</span>
                  </div>
                </OnViewAimation>
              </OnViewAimation>
            </Col>
            <Col
              id='main-image-container'
              className=' ps-md-5 order-1 flex-nowrap order-lg-2 px-2'
              lg={7}>
              <OnViewAimation
                delay='0.3'
                animation={{ x: 0 }}
                initial={{ x: "200" }}>
                <MainImage></MainImage>
              </OnViewAimation>
            </Col>
            {isSmall && (
              <div
                className='mouse-scroll-icon-mobile'
                onClick={() => {
                  GotoById("_about", 50);
                }}>
                <Container className='flexCenter w-100'>
                  <DownScrollIcon />
                </Container>
              </div>
            )}{" "}
          </Row>
        </Container>
      </section>
      <div id='_about'></div>
    </React.Fragment>
  );
};

export default Section;

const MainImage = () => {
  const { isMobile } = useBreakpoint();
  const [selectedFolder, setSelectedFolder] = useState("main");
  const [startA, setStartA] = useState(false);
  const toggleFolder = () => {
    setSelectedFolder((old) => (old === "hover" ? "main" : "hover"));
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setStartA(true);
        toggleFolder();
      }, 2000);
    }
  }, [isMobile]);

  return (
    <Continer
      whileHover='rest'
      whileInView={startA ? "rest" : ""}
      onHoverStart={() => {
        if (isMobile) return;
        else setSelectedFolder("hover");
      }}
      onHoverEnd={() => {
        if (isMobile) return;
        else setSelectedFolder("main");
      }}
      className='  w-100 h-100  '>
      <Row className=' h-100 main-images text-center justify-content-center'>
        <Col xs='2' lg className='h-100 position-relative  text-center'>
          <FirstImage
            firstItem
            toggleFolder={toggleFolder}
            src={`images/header/${selectedFolder}/img5.png`}
          />
        </Col>
        <Col xs='2' lg className='h-100 text-center '>
          <SecondImage
            toggleFolder={toggleFolder}
            src={`images/header/${selectedFolder}/img4.png`}
          />
        </Col>
        <Col xs='2' lg className='h-100  text-center'>
          <FirstImage
            toggleFolder={toggleFolder}
            src={`images/header/${selectedFolder}/img3.png`}
          />
        </Col>
        <Col xs='2' lg className='h-100  text-center'>
          <SecondImage
            toggleFolder={toggleFolder}
            src={`images/header/${selectedFolder}/img2.png`}
          />
        </Col>
        <Col xs='2' lg className='h-100 text-center '>
          <FirstImage
            toggleFolder={toggleFolder}
            src={`images/header/${selectedFolder}/img1.png`}
          />
        </Col>
      </Row>
    </Continer>
  );
};

const Continer = motion.div;

const firstImageDiv = {
  rest: {
    type: "spring",
    duration: 3,
    bounce: 0.6,
    opacity: 1,
    y: -55,
  },
};
const firstImageDivMobile = {
  rest: {
    type: "spring",
    duration: 0.5,

    bounce: 0.6,
    opacity: 1,
    y: -40,
    transition: {
      yoyo: Infinity,
      repeatDelay: 2,
    },
  },
};
const secondImageDiv = {
  rest: {
    type: "spring",
    duration: 0.5,
    opacity: 1,
    y: 25,
    bounce: 0.6,
  },
};
const secondImageDivMobile = {
  rest: {
    y: 15,
    type: "spring",
    duration: 0.5,
    transition: {
      opacity: 1,
      yoyo: Infinity,
      repeatDelay: 2,
    },
  },
};

const FirstImage = ({ src, toggleFolder, firstItem }) => {
  const { isMobile, md } = useBreakpoint();
  useEffect(() => {
    if (firstItem && isMobile) {
      // toggleFolder();
    }
  }, [isMobile]);
  // let calcWidth = 100 / 6 + 1;
  // if (md) {
  //   calcWidth = 100 / 6 + 2;
  // }
  // if (isMobile) {
  //   calcWidth = 100 / 5.4;
  // }
  return (
    <AnimatePresence>
      <motion.img
        className='main-images-item'
        onUpdate={(xx, cc) => {
          if (firstItem && isMobile) {
            if (xx.y === 10 || xx.y === -40) {
              toggleFolder();
            }
          }
        }}
        // end
        key={String(isMobile)}
        src={src}
        alt=''
        initial={{ y: isMobile ? "10px" : "30px" }}
        // initial='ini'
        variants={isMobile ? firstImageDivMobile : firstImageDiv}>
        {/* <div className='main-image-border-inside'></div> */}
      </motion.img>
    </AnimatePresence>
  );
};
const SecondImage = ({ src, toggleFolder }) => {
  const { md, isMobile } = useBreakpoint();

  // let calcWidth = 100 / 6 + 1;

  // if (md) {
  //   calcWidth = 100 / 6 + 2;
  // }
  // let y = "-60px";
  // if (isMobile) {
  //   y = "-30px";
  //   calcWidth = 100 / 5.4;
  // }
  return (
    <AnimatePresence>
      <motion.img
        className='main-images-item'
        style={
          {
            // width: `${100}%`,
            // border: "10px black solid",
            // height: "70vh",
          }
        }
        key={String(isMobile)}
        initial={{ y: isMobile ? "-30px" : "-60px" }}
        src={src}
        alt=''
        variants={isMobile ? secondImageDivMobile : secondImageDiv}>
        {/* <div className='main-image-border-inside'></div> */}
      </motion.img>
    </AnimatePresence>
  );
};
