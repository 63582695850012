import React, { useEffect, useState } from "react";
import routes from "../../routes";
import { AnimatePresence, motion } from "framer-motion";
import { Route, Switch, Link } from "react-router-dom";
import useBreakpoint, { ScrollToTop, useAxios } from "../../component/helper";
import logolight from "../../assets/images/logonotxt.png";

// Importing Section
import Navbar from "../../component/Navbar/NavBar";

// const News = React.lazy(() => import("../../component/News"));
// const Contact = React.lazy(() => import("../../component/Contact"));
import Footer from "../../component/Footer/Footer";
import { URLS } from "../../urls";
//set preloader div
export const PreLoader = () => {
  var element = document.getElementById("nav-overly");
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    try {
    } catch (error) {}
    return () => {};
  }, [element]);
  // nav-overly show
  return (
    <div id='preloader'>
      <div className='overly'>
        <div></div>
      </div>
      <div id='status'>
        <div>
          <img src={logolight} alt='' height={isMobile ? 100 : 120} />
        </div>
        {/* <div className='spinner'>
          <div className='bounce1'></div>
          <div className='bounce2'></div>
          <div className='bounce3'></div>
        </div> */}
      </div>
    </div>
  );
};
const Layout1 = (props) => {
  const [state, _setState] = useState({
    navItems: [
      { id: 1, idnm: "home", navheading: "Home" },
      { id: 2, idnm: "about", navheading: "About Us" },
    ],
    pos: document.documentElement.scrollTop,
    imglight: false,
    navClass: "",
  });
  const [data, isLoading] = useAxios({
    url: URLS.GET_CONTACT_INFO,
    method: "get",
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);

    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
    };
  }, []);

  const setState = (newData) => {
    _setState({ ...state, ...newData });
  };
  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;

    if (scrollup > state.pos) {
      setState({ navClass: "nav-sticky", imglight: false });
    } else {
      setState({ navClass: "", imglight: false });
    }
  };

  return (
    <React.Fragment>
      <Navbar
        data={data}
        navItems={state.navItems}
        navClass={state.navClass}
        imglight={state.imglight}
      />
      <ScrollToTop />
      {/* <Suspense fallback={PreLoader()}> */}
      <AnimatePresence>
        <Switch>
          {routes.map((route, idx) => (
            <Route
              path={route.path}
              component={route.component}
              exact={route.exact}
              key={idx}
            />
          ))}
        </Switch>
      </AnimatePresence>

      <Footer data={data?.info} />
      {/* </Suspense> */}
    </React.Fragment>
  );
};
export default Layout1;
