import React, { useEffect, useState } from "react";
import {
  PagesAimation,
  useAxios,
  useCacehdImagesList,
} from "../../component/helper";

import $ from "jquery";
import OurLocations from "../../component/OurLocations";
import { URLS } from "../../urls";
import Helmate from "react-helmet";
import Section from "./Section";
import About from "../../component/About";
import Feature from "../../component/Feature";
import WhatWeDo from "../../component/WhatWeDo";
import Partner from "../../component/Partner";
import NewsBar from "../../component/NewsBar";
import { PreLoader } from "./Layout1";
import { OnViewAimation } from "../../component/animationHelper";
const CahedImages = [
  // should load before render ther page
  { url: "images/header/main/img1.png" },
  { url: "images/header/main/img2.png" },
  { url: "images/header/main/img3.png" },
  { url: "images/header/main/img4.png" },
  { url: "images/header/main/img5.png" },

  { url: "images/header/hover/img1.png" },
  { url: "images/header/hover/img2.png" },
  { url: "images/header/hover/img3.png" },
  { url: "images/header/hover/img4.png" },
  { url: "images/header/hover/img5.png" },
];
const Home = (props) => {
  const [data, isLoading] = useAxios({
    url: URLS.HOME_PAGE_DATA,
    method: "get",
  });

  const [isImagesLoaded] = useCacehdImagesList(CahedImages);
  return (
    <div style={{ minHeight: "100vh" }}>
      <Helmate>
        <title>Quazar</title>
        <link rel='canonical' href={window.location.origin} />
        <meta name='description' content='Empowering Business World'></meta>

        <meta name='og:title' content={"Quazar"}></meta>
        <meta name='og:description' content='Empowering Business World'></meta>
        <meta property='og:url' content={window.location.origin} />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content={"../../assets/images/logonotxt.png"}
        />
      </Helmate>
      {!isLoading && isImagesLoaded && data ? (
        <PagesAimation>
          <PagesAimation>
            <Section data={data} />
          </PagesAimation>
          <About data={data} />
          <WhatWeDo data={data} />
          <OnViewAimation
            delay='0.5'
            animation={{ x: 0 }}
            initial={{ x: "-100" }}>
            <Feature list={data.addtional_support} />
          </OnViewAimation>
          <OurLocations
            list={data.cities}
            what_we_do_in_hub_paragraph={data.what_we_do_in_hub_paragraph}
          />
          <OnViewAimation
            delay='0.5'
            animation={{ x: 0 }}
            initial={{ x: "-100" }}>
            <Partner list={data.partners} />
          </OnViewAimation>
          <OnViewAimation
            delay='0.5'
            animation={{ x: 0 }}
            initial={{ x: "100" }}>
            <NewsBar list={data.news} />
          </OnViewAimation>
        </PagesAimation>
      ) : (
        <PreLoader />
      )}
    </div>
  );
};

export default Home;
