import React from "react";
import { Col, Container, Row } from "reactstrap";

//import icon
import { motion } from "framer-motion";
import { OnViewAimation } from "./animationHelper";
import parse from "html-react-parser";
import useBreakpoint, { setActiveNavItem } from "./helper";

const About = ({ data }) => {
  const { about_us, team, aim_and_vision, core_value } = data;
  const getextraWord = (first, second) => {
    if (first.length >= second.length) return "";
    else {
      let remaingChars = second.length - first.length;
      return second.substr(0, remaingChars);
    }
  };
  const { isMobile } = useBreakpoint();
  return (
    <React.Fragment>
      <section className='section overflow-hidden ' id='about'>
        <Container className='px-lg-0 px-md-0 px-xl-0'>
          <Row className='justify-content-center align-items-center'>
            <Col lg={6}>
              <OnViewAimation
                delay='0.5'
                animation={{ x: 0 }}
                initial={{ x: "-100" }}>
                <div className='text-left mb-3 mb-md-5  px-4'>
                  <h4 className='text-primary ff-raleway'>About us</h4>
                  <p className=' about-containt  text-white  mfz-m  ff-raleway textJustify'>
                    {parse(about_us.content)}
                  </p>
                </div>
              </OnViewAimation>
            </Col>

            <Col lg={6}>
              <OnViewAimation
                delay='0.5'
                animation={{ x: 0 }}
                initial={{ x: "100" }}>
                <div className='main-image-container ff-raleway'>
                  <div className='my-2'>
                    <CardImage className='first' src={about_us.image_url} />
                  </div>
                  <CardText
                    className='text-center ff-raleway '
                    primaryText={about_us.position}
                    secondaryText={about_us.paragraph}
                  />
                </div>
              </OnViewAimation>
            </Col>
          </Row>
          <Row className=' my-1 my-md-5 '>
            {team.map((t, index) => {
              return (
                <Col
                  key={index}
                  md={12}
                  lg={12}
                  xl={6}
                  className='text-center text-md-start'>
                  <OnViewAimation
                    delay='0.5'
                    animation={{ y: 0 }}
                    initial={{ y: "100" }}>
                    <div className='row-image-container  flex-md-row flex-column   d-flex align-items-start justify-content-lg-center justify-content-md-start'>
                      <div className='my-2 mx-auto'>
                        <CardImage className=' second ' src={t.image_url} />
                      </div>
                      <CardText
                        className=' mx-auto text-md-left pt-3 pe-md-3 pe-0 ff-raleway '
                        primaryText={t.position}
                        desc={t.description}
                        secondaryText={t.name}
                      />
                    </div>
                  </OnViewAimation>
                </Col>
              );
            })}
          </Row>

          <Row className=' align-items-center my-5 align-content-center about-container'>
            <Col lg={6}>
              <OnViewAimation
                delay='0.5'
                animation={{ x: 0 }}
                initial={{ x: "-30" }}>
                <AboutItem
                  extraword={getextraWord(
                    aim_and_vision.paragraph1,
                    aim_and_vision.paragraph2,
                  )}
                  title={aim_and_vision.title1}>
                  {aim_and_vision.paragraph1}
                </AboutItem>
              </OnViewAimation>
            </Col>
            <Col lg={6}>
              <OnViewAimation
                delay='0.5'
                animation={{ x: 0 }}
                initial={{ x: "30" }}>
                <AboutItem
                  title={aim_and_vision.title2}
                  extraword={getextraWord(
                    aim_and_vision.paragraph2,
                    aim_and_vision.paragraph1,
                  )}>
                  {aim_and_vision.paragraph2}
                </AboutItem>
              </OnViewAimation>
            </Col>
            <Col lg={12}>
              <OnViewAimation
                delay='0.5'
                animation={{ y: 0 }}
                initial={{ y: "10" }}>
                <AboutItem title={aim_and_vision.title3}>
                  {aim_and_vision.paragraph3}
                </AboutItem>
              </OnViewAimation>
            </Col>
          </Row>

          <Row className='overflow-hidden pb-4'>
            <CoreValue list={core_value} isMobile={isMobile}></CoreValue>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;
const CardText = ({ className, primaryText, secondaryText, desc }) => {
  return (
    <div className={className}>
      <h6 className='text-white text-bold fw-bolder ff-raleway   my-2'>
        {secondaryText}
      </h6>
      <h6 className='text-primary ff-raleway my-2'>{primaryText}</h6>
      <p
        className='text-white px-2 px-md-0 text-start ff-raleway textJustify  '
        style={{ fontSize: "14px", lineHeight: "18px" }}>
        {desc}
      </p>
    </div>
  );
};
const CardImage = ({ className, width, src }) => {
  return (
    <div className={className + " position-relative card-image"}>
      <img src={src} />
      <div className='image-overlay'></div>
    </div>
  );
};
const AboutItem = ({ className, title, children, extraword }) => {
  const { isMobile } = useBreakpoint();
  return (
    <div
      className={
        className +
        " position-relative about-item  d-flex flex-column align-content-between ff-raleway "
      }>
      <h5
        id={"title"}
        className=' text-center fw-bold  ff-raleway text-white   mb-2'>
        {title}
      </h5>
      <p className='ff-raleway mfz-m  text-center text-white'>{children}</p>
      {/* {extraword && !isMobile && (
        <p className='invisible mb-0'>{extraword.substr(0, 10)}</p>
      )} */}
    </div>
  );
};

const CoreValue = ({ list, isMobile }) => {
  const listPosistions = [
    {
      x: "0",
      y: "-40%",
    },
    {
      x: "25%",
      y: "-26%",
    },
    {
      x: "36%",
      y: "6%",
    },
    {
      x: "17%",
      y: "+34%",
    },

    {
      x: "-16.5%",
      y: "+34.5%",
    },
    {
      x: "-36%",
      y: "+6%",
    },
    {
      x: "-25%",
      y: "-26%",
    },
  ];

  const mixArraies = () => {
    return listPosistions.map((a, index) => {
      return { ...a, ...list[index] };
    });
  };
  return (
    <div id='core-value'>
      <motion.img
        viewport={{ once: true, amount: "some" }}
        initial={{
          opacity: 0,
          scale: 0,
          // transform: "translate(-50%,-50%)",
        }}
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.6, type: "spring" },
        }}
        src='images/about/core-value/bg12.png'
        className='main-img z-1'></motion.img>
      {isMobile ? (
        <h3 className='title text-primary fw-bolder flexCenter '>
          Core
          <br /> value
        </h3>
      ) : (
        <h3 className='title text-primary fw-bolder flexCenter'>Core value</h3>
      )}

      <div className='div-overlay flexCenter'>
        {mixArraies().map((l, index) => {
          return (
            <CoreValueItem
              key={index}
              text={l.name}
              image_url={l.image_url}
              x={l.x}
              y={l.y}
            />
          );
        })}
      </div>
    </div>
  );
};

const CoreValueItem = ({ text, image_url, x = 0, y = 0 }) => {
  const { isMobile } = useBreakpoint();

  return (
    <Item
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
      className='flexCenter'
      initial={{
        opacity: 0,
        x: 0,
        // transform: "translate(-50%,-50%)",
      }}
      viewport={{
        once: true,
        amount: "some",
        margin: isMobile ? "-100px" : "-200px",
      }}
      whileInView={{
        x: x,
        y: y,
        opacity: 1,
        transition: { duration: 0.5, type: "spring" },
      }}>
      <div
        // style={{ top: top, left: left }}
        className={"core-value-item z-2 text-center  "}>
        {isMobile ? (
          <>
            <img src={image_url} alt={text} />
            <p className='text-white mx-4  my-1  mfz-m text-nowrap'>{text}</p>
          </>
        ) : (
          <>
            <img src={image_url} alt={text} />
            <p className='text-white mx-4 my-1 text-nowrap'>{text}</p>
          </>
        )}
      </div>
    </Item>
  );
};

const Item = motion.div;
