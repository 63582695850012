import { motion } from "framer-motion";
import React, { useEffect } from "react";
import useBreakpoint, { PagesAimation } from "../component/helper";
import xHistory from "../history";

const NotFoundPage = (props) => {
  useEffect(() => {}, []);
  const { isMobile } = useBreakpoint();
  return (
    <div
      id='page-not-found'
      style={{ minHeight: "50vh" }}
      className={" page flexCenter"}>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ y: 0, opacity: 1 }}
        className='text-center'>
        <div className='main-box mt-3'>
          <div className=' flexCenter'>
            <span className='text-bold'>404</span>
            <span className=''>Page Not Found</span>
          </div>
        </div>
        <button
          onClick={() => {
            xHistory.push("/");
          }}
          className='btn btn-secondary white-hover br-5 px-5 br-25  py-2 mt-4'>
          Go to homepage
        </button>
      </motion.div>
    </div>
  );
};

export default NotFoundPage;
