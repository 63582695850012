import React from "react";
import { Container, Row, Col } from "reactstrap";

const Partner = ({ list }) => {
  return (
    <React.Fragment>
      <section className='section' id='partner'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={12}>
              <div className='text-left text-white mb-2'>
                <h3 className='text-primary fw-bolder'>
                  Associates and Partners
                </h3>
              </div>
            </Col>
          </Row>
          <div className='features-content'>
            <Row className=' align-items-center support-container'>
              {list.map((l, index) => (
                <PartnerItem
                  key={l.name}
                  title={l.name}
                  image_url={l.image_url}></PartnerItem>
              ))}
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Partner;

const PartnerItem = ({ image_url, title, children }) => {
  return (
    //
    <Col xs={4} md={4} lg={2} className='partner-item my-1 px-2'>
      <div className=' text-center z-2 px-3 flexCenter overflow-hidden '>
        <img src={image_url} alt={title} width='auto' height='100px' />
      </div>
    </Col>
  );
};
