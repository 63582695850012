/* eslint-disable jsx-a11y/iframe-has-title */
import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Input, Form, Button } from "reactstrap";
import xHistory from "../../history";
import { URLS } from "../../urls";
import { OnViewAimation } from "../animationHelper";
import { GotoById } from "../helper";
import $ from "jquery";
import { useState } from "react";

export let PRIVACY_DATA = "";

const Footer = ({ data }) => {
  const [info, setInfo] = useState(data || {});

  useEffect(() => {
    if (!info.email) {
      axios.get(URLS.GET_CONTACT_INFO).then((res) => {
        setInfo(res?.data?.info || {});
        PRIVACY_DATA = res?.data?.info?.policy_privacy || "<></>";
      });
    } else {
      PRIVACY_DATA = data?.info?.policy_privacy || "<></>";
    }
  }, []);

  return useMemo(
    () => (
      <React.Fragment>
        {/* Footer Start */}
        <footer id='footer' className='footer ff-raleway'>
          <Location info={info} />
          <OnViewAimation
            id='footer-float-form'
            animation={{ x: 0 }}
            duration={0.4}
            delay={0.1}
            initial={{ x: "-100" }}>
            <ContactForm info={info} />
          </OnViewAimation>

          <OnViewAimation
            id='footer-float-section'
            animation={{ y: 0 }}
            delay={0.1}
            duration={0.4}
            initial={{ y: "100" }}>
            <Container>
              <Row className='justify-content-center align-items-center'>
                <Col className='flexCenter mb-2 footer-logo'>
                  <div>
                    <Link to='#'>
                      <img
                        src={"images/logo-no-icon.svg"}
                        alt=''
                        className=''
                        height='35'
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg={12}>
                  <hr id='footer-line' className=' my-4' />
                </Col>
              </Row>
              <Row className='justify-content-center align-items-center z-2 position-relative'>
                <Col lg={9} className='text-center'>
                  <Row className='my-2 footer-link-container'>
                    {/* Render Footer Link */}

                    <Col
                      xs={{ order: 1, size: 4 }}
                      sm={4}
                      md={2}
                      className='my-1'>
                      <Link className='footer-link' to={"/"}>
                        Home
                      </Link>
                    </Col>
                    <Col
                      xs={{ order: 4, size: 4 }}
                      sm={4}
                      md={{ order: 2, size: 2 }}
                      className='my-1'>
                      <Link
                        className='footer-link'
                        onClick={() => {
                          if (document.location?.pathname === "/")
                            GotoById("_about");
                          else xHistory.push("/?s=_about");
                        }}>
                        About Us
                      </Link>
                    </Col>
                    <Col
                      xs={{ order: 2, size: 4 }}
                      sm={4}
                      md={{ order: 3, size: 2 }}
                      className='my-1'>
                      <Link
                        className='footer-link'
                        onClick={() => xHistory.push("/what-we-do")}>
                        What We Do
                      </Link>
                    </Col>
                    <Col
                      xs={{ order: 5, size: 4 }}
                      sm={4}
                      md={{ order: 3, size: 2 }}
                      className='my-1'>
                      <Link
                        className='footer-link '
                        onClick={() => {
                          if (document.location?.pathname === "/")
                            GotoById("our-location", 50);
                          else xHistory.push("/?s=our-location");

                          // setTimeout(() => {
                          //   GotoById("our-location", 50);
                          // });
                        }}>
                        Quazar HUB
                      </Link>
                    </Col>
                    <Col
                      xs={{ order: 3, size: 4 }}
                      sm={4}
                      md={2}
                      className='my-1'>
                      <Link
                        onClick={() => {
                          if (document.location?.pathname.includes("news")) {
                            console.log(
                              "document.location?.pathname",
                              document.location?.pathname,
                            );
                            if (document.location?.search.includes("id"))
                              return xHistory.push("/news");
                            else return GotoById("news-page", 50);
                          }

                          return xHistory.push("/news");
                        }}
                        className='footer-link'>
                        News
                      </Link>
                    </Col>
                    <Col
                      style={{ order: 6 }}
                      xs={{ size: 4 }}
                      sm={4}
                      md={2}
                      className='my-1'>
                      <Link
                        onClick={() => {
                          const ell = document.getElementsByClassName(
                            "card-content-container",
                          );
                          if (ell?.length > 0) {
                            // const target = ell[0].getElementById("news-footer");
                            // const top = rect.top + window.scrollY;
                            ell[0]?.scrollTo(0, ell[0].scrollHeight - 1000, {
                              behavior: "smooth",
                            });
                          } else GotoById("footer");
                        }}
                        className='footer-link'>
                        Contact Us
                      </Link>
                    </Col>
                  </Row>
                  <Row className='justify-content-center my-3'>
                    <Col lg='auto'>
                      <Link
                        onClick={() => {
                          window.open(info.twitter);
                        }}
                        className='icon-link text-white-70 mx-3'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='35.999'
                          height='35.999'
                          viewBox='0 0 35.999 35.999'>
                          <path
                            id='circletwitter'
                            d='M18,36a17.657,17.657,0,0,1-7-1.424A17.612,17.612,0,0,1,1.424,25,17.9,17.9,0,0,1,1.424,11,17.612,17.612,0,0,1,11,1.424,17.9,17.9,0,0,1,25,1.424,17.612,17.612,0,0,1,34.575,11a17.9,17.9,0,0,1,0,13.992A17.612,17.612,0,0,1,25,34.575,17.656,17.656,0,0,1,18,36ZM28.581,9a6.5,6.5,0,0,0-.633.387l-.686.457-.65.352-.738.246A4.143,4.143,0,0,0,22.675,9q-4.113,0-4.113,3.445V14.52a11.12,11.12,0,0,1-8.684-4.148,2.839,2.839,0,0,0-.879,2,4.006,4.006,0,0,0,1.723,3.516q-.211,0-.6.035a2.665,2.665,0,0,1-.615,0A1.762,1.762,0,0,1,9,15.75a4.157,4.157,0,0,0,.861,2.689,3.97,3.97,0,0,0,2.373,1.388,1.211,1.211,0,0,1-.984.422,1.594,1.594,0,0,1-.984-.316,2.346,2.346,0,0,0,1.318,2.127,6.131,6.131,0,0,0,2.971.791,3.812,3.812,0,0,1-1.846,1.424,7.008,7.008,0,0,1-2.584.474A5.288,5.288,0,0,1,8.771,24.5q-.862-.246-.9-.246a4.594,4.594,0,0,0,2.3,1.934A10.473,10.473,0,0,0,14.59,27a11.6,11.6,0,0,0,4.395-.826,10.931,10.931,0,0,0,3.48-2.2,14.434,14.434,0,0,0,2.479-3.129,14.628,14.628,0,0,0,1.547-3.639A14.212,14.212,0,0,0,27,13.5q0-.07.422-.3a11.344,11.344,0,0,0,.984-.615,3.11,3.11,0,0,0,.844-.809q-1.9,0-2.531.07A4.723,4.723,0,0,0,28.581,9Z'
                            transform='translate(0 0)'
                          />
                        </svg>
                      </Link>
                      <Link
                        onClick={() => {
                          window.open(info.linkedin);
                        }}
                        className=' icon-link text-white-70 mx-3'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='35.999'
                          height='35.999'
                          viewBox='0 0 35.999 35.999'>
                          <path
                            id='Subtraction_4'
                            data-name='Subtraction 4'
                            d='M18,36A18,18,0,0,1,5.272,5.271,18,18,0,0,1,30.726,30.728,17.882,17.882,0,0,1,18,36Zm3.181-18.633c1.279,0,1.955.908,1.955,2.627V26.2h3.855V19.545c0-3.27-1.66-5.221-4.441-5.221a3.806,3.806,0,0,0-3.476,1.919V14.6H15.218c.05,1.076,0,11.5,0,11.6h3.855v-6.48a2.578,2.578,0,0,1,.129-.938A2.146,2.146,0,0,1,21.181,17.366ZM9.228,14.6V26.2h3.855V14.6ZM11.18,9a2.009,2.009,0,1,0-.051,4.006h.027A2.01,2.01,0,1,0,11.18,9Z'
                            transform='translate(0)'
                          />
                        </svg>
                      </Link>{" "}
                    </Col>
                  </Row>

                  <Row className='justify-content-center my-2'>
                    <Col lg='auto'>
                      <Link
                        onClick={() => {
                          xHistory.push("/careers");
                        }}
                        className='text-white text-decoration-underline mx-2'>
                        Careers
                      </Link>
                      <Link
                        onClick={() => {
                          xHistory.push("/privacy-policy");
                        }}
                        className='text-white  text-decoration-underline mx-2'>
                        Privacy Policy
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Render Footer Link End */}
              {/* <FooterLink /> */}
            </Container>

            <div id='overlay' className='z-1'></div>
          </OnViewAimation>
        </footer>
        <div className='Copyright text-white-70 ff-raleway  text-nowrap'>
          Copyright © 2022 Quazar Investment - All rights reserved.
        </div>
        {/* Footer End */}
      </React.Fragment>
    ),
    [info],
  );
};

export default Footer;

const Location = ({ info }) => {
  useEffect(() => {
    setTimeout(() => {
      $("#google-map-footer").contents().find("div").addClass("d-none");
      // .html("Hey, i`ve changed content of <body>! Yay!!!");
    }, 2000);
  }, []);

  useEffect(() => {
    const ifameData = document.getElementById("google-map-footer");
    ifameData.src = `https://maps.google.com/maps?q=${info.lat},${info.lng}&hl=es;&output=embed`;
  }, [info]);

  return (
    <div className='google-map-code'>
      <iframe
        id='google-map-footer'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.497602525908!2d55.23068118006846!3d25.152670658534646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3593ee27c83570f5!2zMjXCsDA5JzEwLjkiTiA1NcKwMTMnNTcuMiJF!5e0!3m2!1sen!2sae!4v1657123044712!5m2!1sen!2sae'
        // loading='lazy'
        // referrerPolicy='origin'
        width='100%'
        height='100%'
        frameBorder='0'
        style={{ border: 0 }}
        allowFullScreen
        aria-hidden='false'
        tabIndex='0'></iframe>
    </div>
  );
};

const ContactForm = ({ info }) => {
  const handleSubmit = (event) => {
    const data = new FormData(event.target);
    event.preventDefault();
    let url = URLS.SEND_MSG;
    axios
      .post(url, data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        setShowSentMsg(true);

        setTimeout(() => {
          setShowSentMsg(false);
        }, 3000);
        document.getElementById("contact-us-form").reset();
      });
  };

  const [showSentMsg, setShowSentMsg] = React.useState(false);
  return (
    <Form
      onSubmit={handleSubmit}
      className='w-100 h-100 ff-reservation  footer-form-font'
      name='footerForm'
      id='contact-us-form'
      autoComplete='off'>
      <Container>
        <Row className='text-align-mobile'>
          <h5 className='text-primary mb-0 fw-bolder '>Get in Touch</h5>
          <Row className='my-1 flexBetween  '>
            <Col sm={12} md className='my-1 text-startx '>
              <Link
                onClick={() => {
                  window.open(`tel:${info.phone?.trim()}`);
                }}
                className={"   icon-link text-white "}>
                <svg width='29.999' height='29' viewBox='0 0 31.999 32'>
                  <path
                    id='Subtraction_5'
                    data-name='Subtraction 5'
                    d='M-151-8542a15.891,15.891,0,0,1-11.313-4.686A15.9,15.9,0,0,1-167-8558a15.9,15.9,0,0,1,4.686-11.313A15.892,15.892,0,0,1-151-8574a15.892,15.892,0,0,1,11.313,4.687A15.9,15.9,0,0,1-135-8558a15.9,15.9,0,0,1-4.686,11.314A15.891,15.891,0,0,1-151-8542Zm-6.294-24a1.835,1.835,0,0,0-1.262.64,1.833,1.833,0,0,0-.436,1.349c.238,3.11,1.954,13.314,14.049,14.008h.1A1.847,1.847,0,0,0-143-8551.85v-3.249a1.228,1.228,0,0,0-.757-1.143l-2.318-.93a1.225,1.225,0,0,0-.48-.1,1.219,1.219,0,0,0-.857.351l-1.334,1.323a5.792,5.792,0,0,1-4.665-4.68l1.311-1.323a1.219,1.219,0,0,0,.27-1.328l-.932-2.3A1.229,1.229,0,0,0-153.9-8566Zm12.459,14.768h-.043c-10.779-.615-12.6-9.207-12.884-12.873v-.048a.617.617,0,0,1,.614-.617h3.25l.921,2.321-1.747,1.767.037.3c0,.053.739,5.408,6.079,6.078l.3.037,1.767-1.755,2.321.93v3.287a.627.627,0,0,1-.207.425A.61.61,0,0,1-144.836-8551.233Z'
                    transform='translate(167 8574.003)'
                  />
                </svg>
                <span className='mx-1 fz-m'>{info.phone}</span>
              </Link>{" "}
            </Col>
            <Col className=' my-1  text-startx ' sm={12} md>
              <Link
                onClick={() => {
                  window.open(`mailto:${info.email}`);
                }}
                className='  icon-link text-white   '>
                <div className=' text-nowrap'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='29'
                    viewBox='0 0 32 32'>
                    <path
                      id='Subtraction_3'
                      data-name='Subtraction 3'
                      d='M16-7968a15.893,15.893,0,0,1-11.314-4.687A15.893,15.893,0,0,1,0-7984a15.9,15.9,0,0,1,4.686-11.313A15.893,15.893,0,0,1,16-8000a15.893,15.893,0,0,1,11.314,4.687A15.9,15.9,0,0,1,32-7984a15.893,15.893,0,0,1-4.686,11.313A15.893,15.893,0,0,1,16-7968Zm-8.224-22.666a1.113,1.113,0,0,0-1.111,1.111v11.109a1.112,1.112,0,0,0,1.111,1.111H23.335a1.112,1.112,0,0,0,1.111-1.111v-11.109a1.113,1.113,0,0,0-1.111-1.111Zm14.7,12.224H8.7l3.89-4.023-.8-.773-4.013,4.151v-9.623l6.906,6.874a1.107,1.107,0,0,0,.784.323,1.1,1.1,0,0,0,.782-.323l7.083-7.046v9.723l-4.088-4.09-.784.784,4.016,4.021Zm-7.011-4.185h0L8.5-7989.555H22.433l-6.964,6.927Z'
                      transform='translate(0 8000)'
                    />
                  </svg>

                  <span className='mx-2 fz-m'>{info.email}</span>
                </div>
              </Link>{" "}
            </Col>
          </Row>
        </Row>
        <Row className=''>
          <hr id='footer-line' className=' my-1 mb-2' />
        </Row>
        <Row className='my-1'>
          <h6 className='text-primary mb-0 text-align-mobile'>
            Send us a message
          </h6>
        </Row>
        <Row>
          <Col className='my-1  px-1' xs={6} md={6} lg={4}>
            <Input
              type='text'
              required
              name='first_name'
              bsSize='sm'
              id='first_name'
              placeholder='First name'
            />
          </Col>
          <Col className='my-1  px-1' xs={6} lg={4}>
            <Input
              type='text'
              name='last_name'
              required
              bsSize='sm'
              id='last_name'
              placeholder='Last name'
            />
          </Col>

          <Col className='my-1 px-1' xs={6} lg={4}>
            <Input
              type='text'
              name='mobile_number'
              bsSize='sm'
              required
              id='mobile_number'
              placeholder='Mobile number'
            />
          </Col>
          <Col className='my-1 px-1' xs={6} lg={4}>
            <Input
              type='email'
              name='email'
              bsSize='sm'
              id='email'
              placeholder='Email'
              required
            />
          </Col>
          <Col className='my-1 px-1' xs={6} lg={4}>
            <Input
              type='text'
              name='company'
              bsSize='sm'
              id='company'
              placeholder='Company name'
            />
          </Col>
          <Col className='my-1 px-1' xs={6} lg={4}>
            <Input
              type='text'
              name='positoin'
              bsSize='sm'
              id='positoin'
              placeholder='Position '
            />
          </Col>

          <Col className='my-1 px-1' xs={12}>
            <Input
              type='textarea'
              name='message'
              bsSize='sm'
              required
              id='message'
              className='form-control-sm form-control  '
              style={{ height: "auto", minHeight: "5rem", maxHeight: "9rem" }}
              placeholder='Your messege'
            />
          </Col>
          <Col className='my-2 bt-3 mx-auto  px-1' xs={6} sm={4} md={12}>
            <Button
              className={`flexCenter  btn btn-primary   btn-lg   btn-outline-light  w-100  text-capitalize  active  `}
              style={{ borderRadius: "10px" }}>
              <motion.span
                key={JSON.stringify(showSentMsg)}
                initial={{ opacity: 0, y: -100 }}
                animate={{ y: 0, opacity: 1 }}
                className='fw-bolder'>
                {showSentMsg ? "Thanks for contacting us!" : "Send"}
              </motion.span>
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
