import React, { Component, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Slider from "react-slick";
import xHistory from "../history";
import { OnViewAimation } from "./animationHelper";
import { LeftIcon, RightIcon } from "./Icons";
import useBreakpoint, { setActiveNavItem, ShowAllItem } from "./helper";

// Import client   Image

const WhatWeDo = (props) => {
  const [state, setState] = useState({ canNext: true, currentIndex: 0 });

  var settings = {
    dots: false,
    speed: 300,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    // centerMode: true,
    // variableWidth: true,
    arrows: false,
    centerPadding: "20px",
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          infinite: false,
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.3,
          infinite: false,

          centerPadding: "0px",
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          infinite: false,

          centerPadding: "20px",
        },
      },
    ],
  };
  const { what_we_do_services, what_we_do_paragraph, all_service_names } =
    props?.data;
  const slides = what_we_do_services.map((item, key) => {
    return <BusniessCard key={item.id} item={item} />;
  });

  const updateCurrentIndex = (newIndex) => {
    setState({ ...state, currentIndex: newIndex });
  };

  // const canNext =
  //   Math.round(state.currentIndex) !== what_we_do_services?.length - 1;

  const canBack = Number(state.currentIndex) > 0;

  const refSlider = useRef(null);
  const { isMobile } = useBreakpoint();
  return (
    <React.Fragment>
      <section className='section' id='clients'>
        <Container>
          <OnViewAimation
            delay='0.3'
            duration={1}
            // onViewportEnter={() => {
            //   setActiveNavItem("Home");
            // }}
            animation={{ x: 0 }}
            initial={{ x: "-400" }}>
            <Row className='justify-content-center '>
              <Col lg={12}>
                <div className='text-left text-white '>
                  <h3 className='text-primary fw-bolder'>What We Do </h3>
                </div>
              </Col>
              <Col lg={12}>
                <p className='text-white mfz-m fz-m textJustify'>
                  {what_we_do_paragraph}
                </p>
              </Col>
              <Row>
                {chunk(
                  all_service_names,
                  isMobile ? all_service_names.length / 2 + 1 : 4,
                ).map((d, index) => {
                  return (
                    <Col key={index} xs={6} md={4} lg={3}>
                      <ul
                        key={index}
                        className='text-white  fz-m ps-md-2 ps-0 item-list-ul'>
                        {d.map((_d) => {
                          return (
                            <li className='mfz-m' key={_d.name}>
                              {_d.name}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  );
                })}
              </Row>
            </Row>
          </OnViewAimation>
          <OnViewAimation
            delay='0.3'
            duration={1}
            animation={{ x: 0 }}
            initial={{ x: "400" }}>
            <Row>
              <Col lg={12} className='flexBetween mb-3 '>
                <h5 className='text-white fw-bold mb-0'>Our Business lines</h5>{" "}
                <div className='flexCenter'>
                  <div
                    data-disabled={!canBack}
                    className='icon-link mx-2'
                    onClick={() => {
                      if (canBack) refSlider.current.slickPrev();
                    }}>
                    <LeftIcon />
                  </div>
                  <div
                    className='icon-link mx-2'
                    data-disabled={!state.canNext}
                    onClick={() => {
                      if (state.canNext) refSlider.current.slickNext();
                    }}>
                    <RightIcon width='30' />
                  </div>
                </div>
              </Col>
              <Col lg={12} style={{ minHeight: "19rem" }}>
                <div
                  id='business-slider'
                  className='owl-carousel owl-theme testi-content textJustify'>
                  <Slider
                    afterChange={(_old, next) => {
                      // if (String(_old).includes(".") || _old >= slides.length) {
                      if (_old + 1 >= slides.length) {
                        setTimeout(() => {
                          setState((old) => ({
                            ...old,
                            canNext: false,
                            currentIndex: _old,
                          }));
                        }, 500);
                      } else {
                        setTimeout(() => {
                          setState((old) => ({
                            ...old,
                            canNext: true,
                            currentIndex: _old,
                          }));
                        }, 500);
                      }
                    }}
                    ref={(_slider) => (refSlider.current = _slider)}
                    {...settings}>
                    {slides}
                    <ShowAllItem
                      onClick={() => {
                        xHistory.push("/what-we-do");
                      }}
                    />
                  </Slider>
                </div>
              </Col>
            </Row>
          </OnViewAimation>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default WhatWeDo;

const chunk = (array, chunkSize) => {
  let result = [];
  let data = array.slice(0);
  while (data[0]) {
    result.push(data.splice(0, chunkSize));
  }
  return result;
};
function BusniessCard({ item }) {
  const { isMobile } = useBreakpoint();

  return (
    <div className='busniess-card'>
      <div
        className='image-container  w-100'
        onClick={() => {
          isMobile && xHistory.push("/what-we-do?id=" + item.id);
        }}>
        <div
          style={{
            backgroundImage: `url(${item.image_url})`,
          }}
          className='img-fluid img  '></div>
        <div className='image-title fz-m read-more'>
          {item.title}{" "}
          {!isMobile && (
            <button
              onClick={() => {
                xHistory.push("/what-we-do?id=" + item.id);
              }}
              className='btn btn-white shadow-none  py-2 px-2 mx-3  text-bold '
              style={{ borderRadius: "10px" }}>
              <div className='text-black '>
                Read more <span className='ml-2 right-icon'>&#8594;</span>
              </div>
            </button>
          )}
        </div>
      </div>
      <p className='text-white fz-m my-3 text-description'>{item.content}</p>
      <div>
        <p
          className='text-muted fz-lg read-more cursor-pointer'
          onClick={() => {
            xHistory.push("/what-we-do?id=" + item.id);
          }}>
          Read more <span className='ml-2 right-icon'>&#8594;</span>
        </p>
      </div>
    </div>
  );
}
