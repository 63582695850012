import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Router } from "react-router-dom";
import xHistory from "./history";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { stopReportingRuntimeErrors } from "react-error-overlay";
 


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer theme='dark' />
    <Router history={xHistory}>
      <App />
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.error = () => {};
console.warn = () => {};
if (process.env.NODE_ENV !== "development") {
  stopReportingRuntimeErrors(); // disables error overlays
  console.error = () => {};
  console.warn = () => {};
  // console.log = () => {};
  // console.info = () => {};
}
