import Home from "./pages/Layout1/Home";
import News from "./pages/Layout1/NewsPage";
import Carrer from "./pages/Layout1/Career";
import Jobs from "./pages/Layout1/WhatWeDo";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyPage from "./pages/PrivacyPage";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const routes = [
  { path: "/", component: Home, exact: true },
  { path: "/what-we-do", component: Jobs, exact: true },
  { path: "/news", component: News, exact: true },
  { path: "/careers", component: Carrer, exact: true },
  { path: "/linkedin", component: LinkedInCallback, exact: true },
  { path: "/privacy-policy", component: PrivacyPage, exact: true },
  { path: "/", component: NotFoundPage, exact: false },
];

export default routes;
